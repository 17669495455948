<template>
  <div :class="$style.top_home_layout">
    <div :class="$style.menu_mv__bg_img">
      <div :class="$style.mv__wrap">
        <div :class="$style.mv__username">こんにちは、{{ nickname }}。</div>
      </div>
      <div :class="[$style.menu_mv__wrap, $style.show_pc]"></div>
      <!-- 不要？202402 -->
    </div>

    <!-- お知らせ -->
    <base-list
      title="お知らせ"
      type="normal"
      :path="noticeIconSrc"
      :imgSize="noticeImgSize"
      :class="$style.tab_wrapper"
      id="tabContainer"
    >
      <base-tab tab-color="underline" :tab="current_tab" @tab="tabChange">
        <template v-slot:[TAB_ALL]>
          <div :class="$style.tab_all">
            <ul :class="$style.content_list">
              <li
                v-for="(post, index) in chatServiceAll"
                :class="[$style.content_item]"
                :key="index"
              >
                <app-notice-list :post="post" />
              </li>
              <li
                v-if="!chats_servicenews || chats_servicenews.length === 0"
                :class="$style.no_post_item"
              >
                投稿はありません。
              </li>
            </ul>
          </div>
          <div :class="$style.list_button_wrapper">
            <div
              v-if="chats_servicenews.length > 6 && count_all > 6"
              :class="$style.list_button_wrap"
            >
              <base-button
                bordered
                display="inline-block"
                width="120px"
                height="40px"
                size="m"
                rounded="rounded"
                @click="closeBtn('all')"
                >閉じる
              </base-button>
            </div>
            <div
              v-if="chats_servicenews.length > count_all || showMoreAll"
              :class="$style.list_button_wrap"
            >
              <base-button
                display="inline-block"
                width="240px"
                height="40px"
                size="m"
                rounded="rounded"
                @click="moreBtn('all')"
                >もっと見る
              </base-button>
            </div>
          </div>
        </template>

        <template v-slot:[TAB_NOTICE]>
          <div :class="$style.tab_notice">
            <ul :class="$style.content_list">
              <li
                v-for="(post, index) in chatServiceNews"
                :class="[$style.content_item]"
                :key="index"
              >
                <app-notice-list :post="post" />
              </li>
              <li
                v-if="!servicenews_list || servicenews_list.length === 0"
                :class="$style.no_post_item"
              >
                投稿はありません。
              </li>
            </ul>
          </div>
          <div :class="$style.list_button_wrapper">
            <div
              v-if="servicenews_list.length > 6 && count_news > 6"
              :class="$style.list_button_wrap"
            >
              <base-button
                bordered
                display="inline-block"
                width="120px"
                height="40px"
                size="m"
                rounded="rounded"
                @click="closeBtn('news')"
                >閉じる
              </base-button>
            </div>
            <div
              v-if="servicenews_list.length > count_news || showMoreNews"
              :class="$style.list_button_wrap"
            >
              <base-button
                display="inline-block"
                width="240px"
                height="40px"
                size="m"
                rounded="rounded"
                @click="moreBtn('news')"
                >もっと見る
              </base-button>
            </div>
          </div>
        </template>

        <template v-slot:[TAB_EVENT]>
          <div :class="$style.tab_event">
            <ul :class="$style.content_list">
              <li
                v-for="(post, index) in chatServiceEvent"
                :class="[$style.content_item]"
                :key="index"
              >
                <app-notice-list :post="post" :service_id="1" />
              </li>
              <li
                v-if="!chat_list[1] || chat_list[1].length === 0"
                :class="$style.no_post_item"
              >
                投稿はありません。
              </li>
            </ul>
          </div>
          <div :class="$style.list_button_wrapper">
            <div
              v-if="chat_list[1].length > 6 && count_event > 6"
              :class="$style.list_button_wrap"
            >
              <base-button
                bordered
                display="inline-block"
                width="120px"
                height="40px"
                size="m"
                rounded="rounded"
                @click="closeBtn('event')"
                >閉じる
              </base-button>
            </div>
            <div
              v-if="chat_list[1].length > count_event || showMoreEvent"
              :class="$style.list_button_wrap"
            >
              <base-button
                display="inline-block"
                width="240px"
                height="40px"
                size="m"
                rounded="rounded"
                @click="moreBtn('event')"
                >もっと見る
              </base-button>
            </div>
          </div>
        </template>

        <template v-slot:[TAB_UNION] v-if="6 in sso_list">
          <div :class="$style.tab_union">
            <ul :class="$style.content_list">
              <li
                v-for="(post, index) in chatServiceUnion"
                :class="[$style.content_item]"
                :key="index"
              >
                <app-notice-list :post="post" :service_id="6" />
              </li>
              <li
                v-if="!chat_list[6] || chat_list[6].length === 0"
                :class="$style.no_post_item"
              >
                投稿はありません。
              </li>
            </ul>
          </div>
          <div :class="$style.list_button_wrapper">
            <div
              v-if="chat_list[6].length > 6 && count_union > 6"
              :class="$style.list_button_wrap"
            >
              <base-button
                bordered
                display="inline-block"
                width="120px"
                height="40px"
                size="m"
                rounded="rounded"
                @click="closeBtn('union')"
                >閉じる
              </base-button>
            </div>
            <div
              v-if="chat_list[6].length > count_union || showMoreUnion"
              :class="$style.list_button_wrap"
            >
              <base-button
                display="inline-block"
                width="240px"
                height="40px"
                size="m"
                rounded="rounded"
                @click="moreBtn('union')"
                >もっと見る
              </base-button>
            </div>
          </div>
        </template>

        <template v-slot:[TAB_QOU] v-if="2 in sso_list">
          <div :class="$style.tab_qou">
            <ul :class="$style.content_list">
              <li
                v-for="(post, index) in chatServiceQou"
                :class="[$style.content_item]"
                :key="index"
              >
                <app-notice-list :post="post" :service_id="2" />
              </li>
              <li
                v-if="!chat_list[2] || chat_list[2].length === 0"
                :class="$style.no_post_item"
              >
                投稿はありません。
              </li>
            </ul>
          </div>
          <div :class="$style.list_button_wrapper">
            <div
              v-if="chat_list[6].length > 6 && count_qou > 6"
              :class="$style.list_button_wrap"
            >
              <base-button
                bordered
                display="inline-block"
                width="120px"
                height="40px"
                size="m"
                rounded="rounded"
                @click="closeBtn('qou')"
                >閉じる
              </base-button>
            </div>
            <div
              v-if="chat_list[2].length > count_qou || showMoreQou"
              :class="$style.list_button_wrap"
            >
              <base-button
                display="inline-block"
                width="240px"
                height="40px"
                size="m"
                rounded="rounded"
                @click="moreBtn('qou')"
                >もっと見る
              </base-button>
            </div>
          </div>
        </template>

        <template v-slot:[TAB_SMART_FLOW] v-if="3 in sso_list">
          <div :class="$style.tab_smartFlow">
            <ul :class="$style.content_list">
              <li
                v-for="(post, index) in chatServiceSmartFlow"
                :class="[$style.content_item]"
                :key="index"
              >
                <app-notice-list
                  :post="post"
                  :sso_list="chatServiceSmartFlow"
                  :service_id="3"
                />
              </li>
              <li
                v-if="!chat_list[3] || chat_list[3].length === 0"
                :class="$style.no_post_item"
              >
                投稿はありません。
              </li>
            </ul>
          </div>
          <div :class="$style.list_button_wrapper">
            <div
              v-if="chat_list[3].length > 6 && count_smart_flow > 6"
              :class="$style.list_button_wrap"
            >
              <base-button
                bordered
                display="inline-block"
                width="120px"
                height="40px"
                size="m"
                rounded="rounded"
                @click="closeBtn('smart_flow')"
                >閉じる
              </base-button>
            </div>
            <div
              :class="$style.list_button_wrap"
              v-if="chat_list[3].length > count_smart_flow || showMoreQou"
            >
              <base-button
                display="inline-block"
                width="240px"
                height="40px"
                size="m"
                rounded="rounded"
                @click="moreBtn('smart_flow')"
                >もっと見る
              </base-button>
            </div>
          </div>
        </template>
      </base-tab>
    </base-list>
    <div :class="$style.sso_item_list_home">
      <div :class="$style.sso_item_wrap">
        <app-carousel-sso-item
          border-color="#E2E2E2"
          :service_id="1"
          :sso_list="reservation_history_url_list"
          @click="goToSso"
          :sso_login_url="sso_login_url_list[1]"
          :home="true"
        />
      </div>
      <div :class="$style.sso_item_wrap" v-if="6 in sso_list">
        <app-carousel-sso-item
          border-color="#E2E2E2"
          :service_id="6"
          :sso_list="sso_list[6]"
          @click="goToSso"
          :home="true"
        />
      </div>
      <div :class="$style.sso_item_wrap" v-if="2 in sso_list">
        <app-carousel-sso-item
          border-color="#E2E2E2"
          :service_id="2"
          :sso_list="sso_list[2]"
          @click="goToSso"
          :home="true"
        />
      </div>
      <div :class="$style.sso_item_wrap" v-if="3 in sso_list">
        <app-carousel-sso-item
          border-color="#E2E2E2"
          :service_id="3"
          :sso_list="sso_list[3]"
          @click="goToSso"
          :home="true"
        />
      </div>
    </div>
    <div :class="$style.list_accordion_wrap">
      <!-- あたらしいトーク -->
      <base-list
        title="あたらしいトーク"
        type="more"
        actionText="トーク一覧を見る"
        :moreAction="`/home/notice`"
        :path="talkIconSrc"
        :imgSize="talkImgSize"
      >
        <div :class="[$style.post_item_list, $style.featured_post_item_list]">
          <div
            v-for="(talk, index) in chat_list_all"
            :class="$style.post_item_wrap"
            :key="index"
          >
            <app-carousel-post-item-movie
              :item="talk"
              :color="talk.team_color ? `rgb(${talk.team_color})` : '#7BE293'"
              :title="talk.title"
              :name="talk.team_name"
              :teamId="talk.team_id"
              :time="talk.created_at"
              :typeName="talk.type_name"
              :image="talk.image_url"
              :movie="false"
              :type="talk.type"
              :profileUrl="talk.profile_url"
              :profilePath="talk.profile_path"
              :goodsCount="talk.goods_count"
              :goodFlg="!!talk.good_flg"
              :readFlg="!!talk.read_flg"
              :readCount="talk.read_count"
              :commentCount="talk.comment_count"
              :chat_user_nickname="talk.chat_user_nickname"
              @click="goTo(talk, talk.type)"
              @like="clickGood(talk)"
              :post="talk"
            />
          </div>
        </div>
      </base-list>
      <!-- お役立ち動画 -->
      <base-list
        title="お役立ち動画"
        :path="movieIconSrc"
        :imgSize="movieImgSize"
        type="more"
        actionText="MOVIEを見る"
        :moreAction="`/union/home`"
        bg="yellow"
        bgColor="rgba(247, 222, 100, 0.2)"
      >
        <div
          :class="[
            $style.post_item_list,
            $style.featured_post_item_list,
            $style.post_item_list_movie,
          ]"
        >
          <div
            v-for="featured_lecture in lecturePosts(featured_lecture_list).slice(
              0,
              10
            )"
            :class="$style.post_item_wrap"
            :key="featured_lecture.id"
          >
            <app-carousel-post-item-movie
              v-if="featured_lecture.lecture_id !== 0"
              :lectureId="featured_lecture.lecture_id"
              :border-color="
                featured_lecture.team_color
                  ? `rgb(${featured_lecture.team_color})`
                  : '#7BE293'
              "
              :title="featured_lecture.title"
              :name="featured_lecture.name"
              :time="featured_lecture.created_at"
              :image="featured_lecture.path"
              :movie="true"
              :type="featured_lecture.type"
              :goodFlg="!!featured_lecture.good_flg"
              :goodsCount="featured_lecture.good_count"
              :viewCount="featured_lecture.lecture_history_count"
              :attentionFlg="featured_lecture.attention_flg"
              :class="[$style.attention]"
              @like="clickGood(featured_lecture)"
              @click="goTo(featured_lecture, featured_lecture.type)"
            />
          </div>
        </div>
        <div :class="$style.list_button_wrapper">

            <div :class="[$style.list_button_wrap, $style.union_salon_button_wrap]">
            <base-button
                    display="inline-block"
                    width="240px"
                    height="40px"
                    size="m"
                    rounded="rounded"
                    color="black"
                    padding="20px 0"
                    bgColor="yellow"
                    @click="$router.push('/union/home', () => {})"
                    >MOVIEを見る
                  </base-button>
            </div>
          </div>
      </base-list>
      <!-- MYチーム一覧 -->
      <base-list
        title="MYチーム一覧"
        type="add"
        actionText="新しいチームを作成する"
        :path="teamIconSrc"
        :imgSize="teamImgSize"
        :addPage="addPage"
        :showCreateTeam="showCreateTeam"
      >
        <div :class="$style.post_item_list_team">
          <div
            v-for="(team, index) in chatTeam"
            :class="$style.team_item_wrap_sp"
            :key="index"
          >
            <app-carousel-team-item-rect
              :team_id="team.team_id"
              :head="team.name"
              :image="team.path"
              :border-color="
                team.team_color ? `rgb(${team.team_color})` : '#7BE293'
              "
              :link="team.url"
              @click="goToTeam(team.team_id)"
              :index="index"
            />
          </div>
        </div>
        <div :class="$style.list_button_wrapper">
          <div
            v-if="team_list.length > 30 && count_team > 30"
            :class="$style.list_button_wrap"
          >
            <base-button
              bordered
              display="inline-block"
              width="120px"
              height="40px"
              size="m"
              rounded="rounded"
              @click="closeBtn('team')"
              >閉じる
            </base-button>
          </div>
          <div
            v-if="team_list.length > count_team || showMoreTeam"
            :class="$style.list_button_wrap"
          >
            <base-button
              display="inline-block"
              width="240px"
              height="40px"
              size="m"
              rounded="rounded"
              @click="moreBtn('team')"
              >もっと見る
            </base-button>
          </div>
        </div>
      </base-list>
    </div>

    <app-scroll-button />
  </div>
</template>

<script>
import BaseList from '@/components/base/BaseList/BaseList'
import AppCarouselPostItemMovie from '@/components/app/AppCarousel/PostItem/AppCarouselPostItemMovie'
import AppCarouselTeamItemRect from '@/components/app/AppCarousel/TeamItem/AppCarouselTeamItemRect'
import AppScrollButton from '@/components/app/AppScrollButton/AppScrollButton'
import VueCookies from 'vue-cookies'
import AppNoticeList from '@/components/app/AppNoticeList/AppNoticeList.vue'
import BaseTab from '@/components/base/BaseTab/BaseTab'
import BaseButton from '@/components/base/BaseButton/BaseButton.vue'
import AppCarouselSsoItem from '@/components/app/AppCarousel/SsoItem/AppCarouselSsoItem.vue'

const cookies = VueCookies

export default {
  name: 'Index',
  layout: 'TopHome',
  components: {
    BaseList,
    AppCarouselTeamItemRect,
    AppScrollButton,
    AppCarouselPostItemMovie,
    AppNoticeList,
    BaseTab,
    BaseButton,
    AppCarouselSsoItem,
  },
  mounted() {
    this.$router.app.$emit('page-updated', {
      right_title: '',
      showSetting: false,
      pageTitle: 'くみあいアプリ HOME',
      headerColor: 'yellow',
    })
    this.getRightTitle()
    this.screenAuthTeamPublic()
    this.sso_login_url_list =
      this.sso_login_url_list_all[process.env.VUE_APP_NODE_ENV]
    this.reservation_history_url_list =
      this.reservation_history_url_list_all[process.env.VUE_APP_NODE_ENV]
  },

  data() {
    const TAB_ALL = 'すべて'
    const TAB_NOTICE = 'お知らせ'
    const TAB_EVENT = 'イベント予約'
    const TAB_UNION = 'UNION ONE SPACE'
    const TAB_QOU = 'ひとログQoU'
    const TAB_SMART_FLOW = 'ユニオンスマートフロー'
    return {
      TAB_ALL,
      TAB_NOTICE,
      TAB_EVENT,
      TAB_UNION,
      TAB_QOU,
      TAB_SMART_FLOW,
      current_tab: TAB_ALL,
      data: [20, 20, 20, 20],
      score: 20,
      average: 0,
      featured_lecture_list: [],
      team_list: [],
      team_belong_list: [],
      servicenews_list: [],
      chat_list: {1:[],2:[],3:[],4:[],5:[],6:[]},
      sso_list: {},
      sso_login_url_list_all: {
        local: {
          1: {
            sso_url:
              'https://j-union.revn5-2.demo.iqnet.co.jp/auth/signon?uuid=',
            redirect_url: 'https://j-union.revn5-2.demo.iqnet.co.jp',
          },
        },
        develop: {
          1: {
            sso_url:
              'https://j-union.revn5-2.demo.iqnet.co.jp/auth/signon?uuid=',
            redirect_url: 'https://j-union.revn5-2.demo.iqnet.co.jp',
          },
        },
        staging: {
          1: {
            sso_url:
              'https://j-union.revn5-2.demo.iqnet.co.jp/auth/signon?uuid=',
            redirect_url: 'https://j-union.revn5-2.demo.iqnet.co.jp',
          },
        },
        production: {
          1: {
            sso_url: 'https://event.revn.jp/auth/signon?uuid=',
            redirect_url: 'https://event.revn.jp',
          },
        },
      },
      sso_login_url_list: {},
      reservation_history_url_list_all: {
        local: {
          1: {
            team_name: '予約履歴へ',
            sso_url:
              'https://j-union.revn5-2.demo.iqnet.co.jp/auth/signon?uuid=',
            redirect_url:
              'https://j-union.revn5-2.demo.iqnet.co.jp/reservations/history',
          },
        },
        develop: {
          1: {
            team_name: '予約履歴へ',
            sso_url:
              'https://j-union.revn5-2.demo.iqnet.co.jp/auth/signon?uuid=',
            redirect_url:
              'https://j-union.revn5-2.demo.iqnet.co.jp/reservations/history',
          },
        },
        staging: {
          1: {
            team_name: '予約履歴へ',
            sso_url:
              'https://j-union.revn5-2.demo.iqnet.co.jp/auth/signon?uuid=',
            redirect_url:
              'https://j-union.revn5-2.demo.iqnet.co.jp/reservations/history',
          },
        },
        production: {
          1: {
            team_name: '予約履歴へ',
            sso_url: 'https://event.revn.jp/auth/signon?uuid=',
            redirect_url: 'https://event.revn.jp/reservations/history',
          },
        },
      },
      reservation_history_url_list: {},
      right_title: '',
      access_token: null,
      refresh_token: null,
      nickname: '',
      showMoreTeam: true,
      noticeImgSize: '40',
      talkImgSize: '42',
      movieImgSize: '30',
      bgColor: 'rgba(247, 222, 100, 0.2)',
      teamImgSize: '42',
      news_list: {},
      event_list: {},
      union_list: {},
      qou_list: {},
      usf_list: {},
      chats_servicenews: [],
      chat_list_all: {},
      count_all: 5,
      count_news: 5,
      count_event: 5,
      count_union: 5,
      count_qou: 5,
      count_smart_flow: 5,
      count_team: 30,
      showMoreAll: false,
      showMoreNews: false,
      showMoreEvent: false,
      showMoreUnion: false,
      showMoreQou: false,
      showMoreSmartFlow: false,
      scrollY: 0,
      showCreateTeam: false,
    }
  },
  async created() {
    this.$router.app.$emit('show-spinner', true)
    await this.initFetch()
    this.$router.app.$emit('show-spinner', false)
    const auth_info = cookies.get('auth_info')
    if (auth_info.access_token) this.access_token = auth_info.access_token
    if (auth_info.refresh_token) this.refresh_token = auth_info.refresh_token
  },
  computed: {
    chatServiceAll() {
      const list = this.chats_servicenews
      return list.slice(0, this.count_all)
    },
    chatServiceNews() {
      const list = this.servicenews_list
      return list.slice(0, this.count_news)
    },
    chatServiceEvent() {
      const list = this.chat_list[1]
      return list.slice(0, this.count_event)
    },
    chatServiceUnion() {
      const list = this.chat_list[6]
      return list.slice(0, this.count_union)
    },
    chatServiceQou() {
      const list = this.chat_list[2]
      return list.slice(0, this.count_qou)
    },
    chatServiceSmartFlow() {
      const list = this.chat_list[3]
      return list.slice(0, this.count_team)
    },
    chatTeam() {
      const list = this.team_list
      return list.slice(0, this.count_team)
    },
    noticeIconSrc() {
      return require('@/assets/images/list/icon-notice.svg')
    },
    talkIconSrc() {
      return require('@/assets/images/list/icon-talk.svg')
    },
    movieIconSrc() {
      return require('@/assets/images/list/icon-movie.svg')
    },
    teamIconSrc() {
      return require('@/assets/images/list/icon-team.svg')
    },
    addPage() {
      return require('@/assets/images/list/icon-team.svg')
    },
  },
  methods: {
    async clickGood(chat) {
      //多重送信させない
      if (this.request_sending) {
        return
      }
      this.request_sending = true
      let url = ''
      let postdata = {}
      let targetFlg = chat.good_flg ? 0 : 1
      if (chat.chat_id == '') {
        url =
          '/api/' +
          this.$constants.API_VERSION +
          '/lecture_history/chat/favorite'
        let chat_users = []
        chat_users.push({
          lecture_id: chat.lecture_id,
          good_flg: targetFlg,
        })
        postdata = {
          chat_users: chat_users,
        }
      } else {
        url = '/api/' + this.$constants.API_VERSION + '/team/chat/favorite'
        let chat_users = []
        chat_users.push({
          chat_id: chat.chat_id,
          good_flg: targetFlg,
        })
        postdata = {
          chat_users: chat_users,
        }
      }

      this.$axios.post(url, postdata).then(() => {
        chat.good_flg = targetFlg
        if (targetFlg) chat.goods_count++
        else chat.goods_count--
      })

      // 投稿内ユーザー登録・更新 [既読可否]
      //送信中フラグの解除
      this.request_sending = false
      this.processing = false
    },
    async updateChatUser(chat_id, params) {
      await this.$axios.post(
        '/api/' + this.$constants.API_VERSION + '/team/chat/favorite',
        {
          chat_users: [Object.assign({ chat_id: chat_id }, params)],
        }
      )
    },
    fixScroll() {
      // 上端を基準とする位置を取得
      const selectedElement = document.getElementById('tabContainer')
      const elementPosition = selectedElement.getBoundingClientRect().top

      // スクロール
      window.scrollTo({
        top: elementPosition,
        behavior: 'smooth',
      })
    },
    closeBtn(type) {
      switch (type) {
        case 'all':
          this.count_all = 5
          this.fixScroll()
          if (this.chats_servicenews.length < this.count_all) {
            this.showMoreAll = false
          } else {
            this.showMoreAll = true
          }
          break
        case 'news':
          this.count_news = 5
          this.fixScroll()
          if (this.servicenews_list.length < this.count_news) {
            this.showMoreNews = false
          } else {
            this.showMoreNews = true
          }
          break
        case 'event':
          this.count_event = 5
          this.fixScroll()
          if (this.chat_list[1].length < this.count_event) {
            this.showMoreEvent = false
          } else {
            this.showMoreEvent = true
          }
          break
        case 'union':
          this.count_union = 5
          this.fixScroll()
          if (this.chat_list[6].length < this.count_union) {
            this.showMoreUnion = false
          } else {
            this.showMoreUnion = true
          }
          break
        case 'qou':
          this.count_qou = 5
          this.fixScroll()
          if (this.chat_list[2].length < this.count_qou) {
            this.showMoreQou = false
          } else {
            this.showMoreQou = true
          }
          break
        case 'smart_flow':
          this.count_smart_flow = 5
          this.fixScroll()
          if (this.chat_list[3].length < this.count_smart_flow) {
            this.showMoreSmartFlow = false
          } else {
            this.showMoreSmartFlow = true
          }
          break
        case 'team':
          this.count_team = 30
          // this.fixScroll()
          if (this.team_list.length < this.count_team) {
            this.showMoreTeam = false
          } else {
            this.showMoreTeam = true
          }
          break
      }
    },
    moreBtn(type) {
      switch (type) {
        case 'all':
          this.count_all += 5
          if (this.chats_servicenews.length < this.count_all) {
            this.showMoreAll = false
          } else {
            this.showMoreAll = true
          }
          break
        case 'news':
          this.count_news += 5
          if (this.servicenews_list.length < this.count_news) {
            this.showMoreNews = false
          } else {
            this.showMoreNews = true
          }
          break
        case 'event':
          this.count_event += 5
          if (this.chat_list[1].length < this.count_event) {
            this.showMoreEvent = false
          } else {
            this.showMoreEvent = true
          }
          break
        case 'union':
          this.count_union += 5
          if (this.chat_list[6].length < this.count_union) {
            this.showMoreUnion = false
          } else {
            this.showMoreUnion = true
          }
          break
        case 'qou':
          this.count_qou += 5
          if (this.chat_list[2].length < this.count_qou) {
            this.showMoreQou = false
          } else {
            this.showMoreQou = true
          }
          break
        case 'smart_flow':
          this.count_smart_flow += 5
          if (this.chat_list[3].length < this.count_smart_flow) {
            this.showMoreSmartFlow = false
          } else {
            this.showMoreSmartFlow = true
          }
          break
        case 'team':
          this.count_team += 30
          if (this.team_list.length < this.count_team) {
            this.showMoreTeam = false
          } else {
            this.showMoreTeam = true
          }
          break
      }
    },
    tabChange(name) {
      this.current_tab = name
    },
    fetchAllNotice() {
      return this.$axios
        .get(
          '/api/' +
            this.$constants.API_VERSION +
            '/user/notification?type=news',
          {}
        )
        .then((res) => {
          let data = [res.data.value.notifications[0]]
          this.news_list = data
        })
    },
    fetchUserScoreInfo() {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/user/score/info', {})
        .then((res) => {
          let data = [
            res.data.value.user.live_score,
            res.data.value.user.work_score,
            res.data.value.user.scene_score,
            res.data.value.user.other_score,
          ]
          this.score = res.data.value.user.user_sum_score
          this.data = data
          this.average = res.data.value.user.deviation_score
        })
    },
    fetchAllNoticeList() {
      return (
        this.$axios
          .get(
            '/api/' +
              this.$constants.API_VERSION +
              '/team/chat/sso_chat_servicenews_list',
            {
              params: { limit: 50 },
            }
          )
          // 全てのお知らせ取得
          .then((res) => {
            this.chats_servicenews = res.data.value.chats_servicenews
          })
      )
    },
    fetchFeaturedLectureList() {
      return (
        this.$axios
          .get('/api/' + this.$constants.API_VERSION + '/featured_lecture/list', {
            params: { limit: 10 },
          })
          // 注目の動画を取得
          .then((res) => {
            this.featured_lecture_list = res.data.value.featured_lecture_list
          })
      )
    },
    fetchTeamList() {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/team/list', {
          params: {
            search_type: 'login',
            sort: 'name',
            direction: 'asc',
            limit: 1000,
          },
        })
        .then((res) => {
          this.team_list = res.data.value.team_list
        })
    },
    fetchTeamBelongList() {
      this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/team/belong/list')
        .then((response) => {
          this.belong_team_list = response.data.value.team_list
        })
        .catch((error) => {
          this.$logerror(error)
        })
    },
    fetchServiceNewsList() {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/servicenews/list', {
          params: {
            sort: 'created_at',
            direction: 'desc',
            limit: 500,
          },
        })
        .then((res) => {
          this.servicenews_list = res.data.value.servicenews_list
        })
    },
    // 組織の投稿一覧（タブバー）
    fetchSsoChatList() {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/team/chat/list', {
          params: {
            limit: '50',
            type: '4',
            display_type: 'all',
          },
        })
        .then((res) => {
          const chat = res.data.value.chats
          const obj = {}
          for (let i = 0; i < chat.length; i++) {
            const chat_item = chat[i]
            const service_id = chat_item.service_id
            switch (true) {
              case /^[1-6]$/.test(service_id):
                if (!(service_id in obj)) obj[service_id] = []
                obj[service_id].push(chat_item)
                break
              default:
                break
            }
          }

          this.chat_list = Object.assign({}, this.chat_list, obj)
        })
    },
    // 組織の投稿一覧（あたらしいトーク）
    fetchSsoChatListAll() {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/team/chat/list', {
          params: {
            type: '1,2,3,4',
            display_type: 'all',
            sort: 'created_at',
            direction: 'desc',
            limit: '8',
            is_ignore_pin: '1',
          },
        })
        .then((res) => {
          this.chat_list_all = res.data.value.chats
        })
    },
    fetchSsoList() {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/auth/sso/list')
        .then((res) => {
          const sso = res.data.value

          const obj = {}
          for (let i = 0; i < sso.length; i++) {
            const sso_item = sso[i]
            const service_id = sso_item.service_id

            switch (true) {
              case /^[1-6]$/.test(service_id):
                if (!(service_id in obj)) obj[service_id] = []
                obj[sso_item.service_id].push(sso_item)

                if (!(service_id in this.chat_list))
                  this.chat_list[service_id] = []

                break
              default:
                break
            }
          }
          this.sso_list = Object.assign({}, this.sso_list, obj)
        })
    },
    async initFetch() {
      await Promise.all([
        this.fetchAllNotice(),
        // チャートの体裁確認用にコメントアウトしています。
        this.fetchUserScoreInfo(),
        this.fetchFeaturedLectureList(),
        this.fetchTeamList(),
        this.fetchTeamBelongList(),
        this.fetchServiceNewsList(),
        this.fetchSsoChatList(),
        this.fetchSsoList(),
        this.getUserInfo(),
        this.fetchAllNoticeList(),
        this.fetchSsoChatListAll(),
      ])
    },
    goToLecture(chat, type) {
      if (chat.chat_id == '') {
        this.$router.push(`/union/course/${chat.lecture_id}`)
      } else {
        if (type == 2) {
          this.$router.push(
            `/team/mini-vote-detail/${chat.team_id}/${chat.chat_id}`
          )
        } else {
          this.$router.push(`/team/post-detail/${chat.team_id}/${chat.chat_id}`)
        }
      }
    },
    goTo(chat, type) {
      if (chat.chat_id == '') {
        // 素早くWクリックした際のNavigationDuplicatedエラー防止のため
        if (this.$route.path != `/union/course/${chat.lecture_id}`) {
          this.$router.push(`/union/course/${chat.lecture_id}`)
        }
      } else {
        if (type == 2) {
          // 素早くWクリックした際のNavigationDuplicatedエラー防止のため
          if (this.$route.path != `/team/mini-vote-detail/${chat.team_id}/${chat.chat_id}`) {
            this.$router.push(
              `/team/mini-vote-detail/${chat.team_id}/${chat.chat_id}`
            )
          }
        } else {
          // 素早くWクリックした際のNavigationDuplicatedエラー防止のため
          if (this.$route.path != `/team/post-detail/${chat.team_id}/${chat.chat_id}`) {
            this.$router.push(`/team/post-detail/${chat.team_id}/${chat.chat_id}`)
          }
        }
      }
    },
    goToTeam(team_id) {
      // 素早くWクリックした際のNavigationDuplicatedエラー防止のため
      if (this.$route.path != `/team/home/${team_id}`) {
        this.$router.push(`/team/home/${team_id}`)
      }
    },
    goToNews(news_id) {
      // 素早くWクリックした際のNavigationDuplicatedエラー防止のため
      if (this.$route.path != `/home/noticetext/${news_id}`) {
        this.$router.push(`/home/noticetext/${news_id}`)
      }
      // location.href = '/team/news/' + news_id
    },
    async goToSso(sso) {

      // IRET_JUNION_UNI_VERSE-2496 期限が切れたアクセストークンの更新を行うためログインチェックAPIを呼び出す
      await this.$axios.get('/api/' + this.$constants.API_VERSION + '/auth/token/check', {}).then((response) => {
        if (response.status != 200) {
          return
        }
      }).catch((error) => {
        console.log(error)
        return
      })
      const auth_info = cookies.get('auth_info')
      if (auth_info.access_token) this.access_token = auth_info.access_token
      if (auth_info.refresh_token) this.refresh_token = auth_info.refresh_token

      let popup = null
      // SSOリクエスト取得処理
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/auth/sso/request', {
          redirect_url: encodeURIComponent(sso.redirect_url),
          error_url: encodeURIComponent(
            `${process.env.VUE_APP_BASE_URL}/error/404`
          ),
          access_token: this.access_token,
          refresh_token: this.refresh_token,
          device: 'PC',
          tel_or_email: this.$store.state.auth.tel_or_email,
        })
        .then((response) => {
          if (response.status != 200) {
            return
          }
          // ポップアップブロックON対策
          popup = window.open(sso.sso_url + response.data.value.uuid)
          if (!popup || popup.closed || typeof popup.closed === 'undefined') {
              alert('くみあいアプリからのお知らせ\n連携サービスへのポップアップがブロックされました。\n端末・ブラウザの設定からポップアップブロックを解除してください。');
          }
        })
        .catch((error) => {
          if (popup) {
            popup.close();
          }
          this.$logerror(error)
          alert(error)
        })
    },
    async goToSsoPost(post) {
      // IRET_JUNION_UNI_VERSE-2496 期限が切れたアクセストークンの更新を行うためログインチェックAPIを呼び出す
      await this.$axios.get('/api/' + this.$constants.API_VERSION + '/auth/token/check', {}).then((response) => {
        if (response.status != 200) {
          return
        }
      }).catch((error) => {
        console.log(error)
        return
      })
      const auth_info = cookies.get('auth_info')
      if (auth_info.access_token) this.access_token = auth_info.access_token
      if (auth_info.refresh_token) this.refresh_token = auth_info.refresh_token

      // SSOリクエスト取得処理
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/auth/sso/request', {
          redirect_url: encodeURIComponent(post.redirect_url),
          error_url: encodeURIComponent(
            `${process.env.VUE_APP_BASE_URL}/error/404`
          ),
          access_token: this.access_token,
          refresh_token: this.refresh_token,
          device: 'PC',
          tel_or_email: this.$store.state.auth.tel_or_email,
        })
        .then((response) => {
          if (response.status != 200) {
            return
          }
          window.open(post.sso_url + response.data.value.uuid)
        })
        .catch((error) => {
          this.$logerror(error)
          alert(error)
        })
    },
    getRightTitle() {
      this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/team/belong/list')
        .then((response) => {
          if (response.data.value.team_list.length > 0) {
            this.right_title = response.data.value.team_list[0].team_name
            return this.right_title
          }
        })
        .catch((error) => {
          this.$logerror(error)
        })
    },
    getUserInfo() {
      this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/user/info')
        .then((response) => {
          this.nickname = response.data.value.user.nickname
          this.profileImgPath = response.data.value.user.profile_image_path
          this.$store.commit('auth/setIsGroupManager', response.data.value.user.is_group_manager);
        })
        .catch((error) => {
          this.$logerror(error)
        })
    },
    sso_logo_imgpath(service_id) {
      try {
        const img = require(`@/assets/images/sso_logo_service_id_${service_id}.png`)
        return img
      } catch (e) {
        return ''
      }
    },
    // 新規投稿のみを取得
    newPosts(featuredChat) {
      return featuredChat.filter((chat) => {
        return chat.chat_id !== 0
      })
    },
    // ちょこっと動画のみを取得
    lecturePosts(featuredChat) {
      return featuredChat.filter((chat) => {
        return chat.lecture_id !== 0
      })
    },
    screenAuthTeamPublic() {
      this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/screen_auth/team/public')
        .then((res) => {
          if (res.data.status == 'success') {
            this.showCreateTeam = res.data.value.belong
            return this.showCreateTeam
          }
      })
      .catch((error) => {
          this.$logerror(error)
        })
    },
  },
}
</script>

<style lang="scss" module>
.top_home_layout {
  max-width: 100vw;
}
.dash_board {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  &_arrow_left,
  &_arrow_right {
    display: none;
    @media (max-width: 1023px) {
      display: flex;
      align-items: center;
      height: 35px;
      margin-bottom: 6px;
      top: 120px;
      color: $keyBlack;
      text-decoration: none;
      top: 84px;
    }
  }
  &_arrow_left {
    left: 0;
  }
  &_arrow_right {
    right: 0;
  }
  .chart_content_chart {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
.sso_post_wrapper {
  display: flex;
  align-items: top;
  min-height: 70px;
  @media (max-width: 767px) {
    display: block;
  }
  .no_post_item {
    margin-top: 10px;
    font-size: 12px;
    margin-left: 7px;
  }
}
.sso_post_wrapper:nth-of-type(1) {
  margin-top: 10px;
}
.sso_post_wrapper {
  margin-bottom: 8px;
}
.sso_logo_wrapper {
  margin-top: 10px;
  margin-right: 0px;
  height: auto;
  width: 136px;
  flex-shrink: 0;
  @include mobile() {
    margin-top: 8px;
  }
  img {
    height: 35px;
    width: 136px;
    vertical-align: top;
    @include mobile() {
      width: 100%;
      height: auto;
    }
  }
}
// phase2.1
.mv__username {
  font-size: 18px;
  font-weight: bold;
}

.category__ttl {
  font-size: 24px;
  font-weight: bold;
}

@media (min-width: 1024px) {
  .menu_mv__bg_img {
    // width: 100vw;
    // background-image: url('../../assets/images/mv_bg.png');
    // background-size: 100%;
    // background-repeat: no-repeat;
  }
}
.list_accordion_wrap {
  max-width: 1200px;
  padding: 0 20px;
  // margin: 0 auto;
  // padding: 20px 20px;
  // background-color: antiquewhite;
  // border-image-source: linear-gradient(
  //   rgba(247, 222, 100, 0.2),
  //   rgba(247, 222, 100, 0.2)
  // );
  // border-image-slice: fill 0;
  // border-image-outset: 0 100vw 0 100vw;
  // border-image-outset: 100vw 100vw 100vw 100vw;
}
.sso_item_list_home {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  border: solid 1px $borderGray;
  padding: 20px;
  margin: -40px 20px 60px;
  @media (min-width: 1191px) {
    display: none;
  }
}
.post_item_list {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  > * {
    width: calc(25% - 24px);
  }
  @media (max-width: 768px) {
    gap: 16px;
    > * {
      width: calc(50% - 8px);
    }
  }
}
.post_item_list_movie > * {
  &:first-child {
    width: calc(50% - 16px);
    > section {
      display: grid;
      grid-template-columns: minmax(50%, 292px) minmax(40%, 1fr);
      @media (max-width: 768px) {
        grid-template-columns: repeat(auto-fill, 100%);
      }

      picture {
        width: 100%;
        max-width: 292px;
        height: 104px;
        @media (max-width: 768px) {
          margin: auto;
        }
        img {
          height: 104px;
        }
      }
      time {
        position: relative;
        display: flex;
        align-items: center;
      }
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  &:nth-child(2) {
    width: calc(50% - 16px);
    > section {
      display: grid;
      grid-template-columns: minmax(50%, 292px) minmax(40%, 1fr);
      @media (max-width: 768px) {
        grid-template-columns: repeat(auto-fill, 100%);
      }

      picture {
        width: 100%;
        max-width: 292px;
        height: 104px;
        @media (max-width: 768px) {
          margin: auto;
        }
        img {
          height: 104px;
        }
      }
      time {
        position: relative;
        display: flex;
        align-items: center;
      }
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

.post_item_list_team {
  display: flex;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 32px 24px;
  margin-bottom: 30px;
  @media screen and (max-width: 1080px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
}
.sso_post_item_wrap {
  margin: 20px 9px;
  @include mobile() {
    margin: 5px 10px;
  }
}
.sso_item_wrap {
  margin: 0;
}
.team_item_wrap_sp {
  // margin-top: 5px;
  // margin-right: 15px;
  // margin-bottom: 5px;
  // margin-left: 32px;
  margin: 0;
}
.show_sp {
  @media (min-width: 1024px) {
    display: none;
  }
}
.show_pc {
  @media (max-width: 1023px) {
    display: none;
  }
}
.mv__wrap {
  display: flex;
  max-width: 1280px;
  margin: 0 auto;
  align-items: center;
  padding: 20px 20px 30px;
  @media (max-width: 768px) {
    padding: 20px 10px 30px;
  }
  .chart_content_wrap {
    width: 360px;
    margin: 5px auto;
    .chart_content_title {
      font-size: 15px;
      font-weight: bold;
      margin: 0;
      position: absolute;
    }
  }
}
.list_button_wrap {
  // width: 100%;
  display: flex;
  justify-content: center;
}

.content_list {
  padding: 0;
  margin: 12px 0 20px 0;
  max-width: 1280px;
  @media (max-width: 768px) {
    margin-top: 0;
  }
}
.content_item {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: solid 1px $borderGray;

  &:not(:last-child) {
    //margin-bottom: 20px;
  }

  & > *:not(:last-child) {
    margin-right: 10px;
  }
}
.tab_wrapper {
  max-width: 1200px;
  padding: 0 20px;
}
.list_button_wrapper {
  display: flex;
  gap: 20px;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
}

.union_salon_button_wrap {
  @media (min-width: 1191px) {
    display: none;
  }
  margin-top: 20px;
}

// 連携サービス（スマホサイズのみ表示）
.link_contents_sso {
  padding: 30px;
  border-radius: 20px;
}
.link_contents_sso {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: scroll;
  width: 100%;
  @media (min-width: 769px) {
    display: none;
  }
}
</style>
