<template>
  <section :class="$style.form">
    <div :class="$style.contents" v-if="!processing">
      <div v-if="!chat_id">
        <h1 :class="$style.post_title">投稿(新規)</h1>
      </div>
      <div v-else>
        <h1 :class="$style.post_title">投稿(編集)</h1>
      </div>

        <div>
          <base-form-field name="title" label="タイトル" expand expanded>
            <base-input
              name="title"
              id="title"
              v-model="chat.title"
              :error="
                v$.chat.title.$invalid && v$.chat.title.$dirty
                  ? v$.chat.title.$errors[0].$message
                  : isFieldErrorMsg('title', errorMsgList)
              "
              maxlength="100"
            />
            <div :class="$style.title_text">{{chat.title.length}}/100</div>
          </base-form-field>

          <base-form-field name="publish" label="公開設定" expand expanded>
          <div :class="[$style.accordion_content_wrap, $style.disclosure]">
          <div :class=$style.radio_row>
              <base-radio
                name="publish"
                value="publish"
                v-model="publish"
                v-on:update="onChangePublish"
                ><span>公開</span></base-radio
              >
              <div :class=$style.radio_margin>
                <base-radio
                  name="publish"
                  value="private"
                  v-model="publish"
                  v-on:update="onChangePublish"
                  ><span>非公開（下書き）</span></base-radio
                >
              </div>
            </div>
            <div :class="$style.radio_row_period">
                <base-radio
                  value="with-limit"
                  v-model="publish"
                  v-on:update="onChangePublish"
                  >期間を指定</base-radio
                >
                <div :class="$style.with_limit" v-if="publish === 'with-limit'">
                  <div :class="$style.time_from">
                  <p :class="$style.range_label">開始日時</p>
                  <base-date-picker
                    v-model="input_start_datetime"
                    :error="isFieldErrorMsg('start_datetime', errorMsgList)"
                  />
                </div>
                <div :class="$style.time_to">
                  <p :class="$style.range_label">終了日時</p>
                  <base-date-picker
                    v-model="input_end_datetime"
                    :error="isFieldErrorMsg('end_datetime', errorMsgList)"
                  />
                </div>
                </div>
              </div>
          </div>
          </base-form-field>

          <base-form-field name="comment-view" label="コメント閲覧設定" expand expanded>
            <div :class=$style.radio_row>
              <base-radio
                name="public"
                value="public"
                v-model="commentView"
                v-on:update="onChangeCommentView"
                >公開</base-radio
              >
              <div :class=$style.radio_margin>
                <base-radio
                  name="private"
                  value="private"
                  v-model="commentView"
                  v-on:update="onChangeCommentView"
                  >非公開 &nbsp; ※組織管理者、投稿した本人は確認できます</base-radio
                >
              </div>
            </div>
          </base-form-field>

          <base-form-field name="to" label="宛先" expand expanded>
            <base-select
              :class="$style.to_select"
              v-model="chat.auth_type"
              :options="toOptions"
            ></base-select>
            <base-select-taggable
              name="to_personal"
              :options="to_personal_options"
              v-model="to_personal_selected"
              multiple
              v-if="chat.auth_type == '2'"
              :loading="to_personal_loading"
              :error="isFieldErrorMsg('to_personal', errorMsgList)"
              :close-on-select="false"
            >
            </base-select-taggable>
          </base-form-field>

          <base-form-field name="color" label="背景色" expand expanded>
            <base-radio-color
              :size="!isMobile ? 'large': ''"
              :values="colorValues"
              v-model="chat.background_color"
              :checked="chat.background_color"
            />
          </base-form-field>

          <base-form-field  name="thumbnail" label="サムネイル" expand expanded>
            <team-base-input-button
              name="cover_image"
              :showStampIcon="false"
              :showRecordIcon="false"
              :showTextArea="false"
              @file_updated="onCoverImageUpload"
              :showTrash="true"
              @file_delete="onCoverImageDelete"
              @error="onCoverImageError"
              :maxFileSize="50"
              :files="cover_image_files"
              :imageTypes="imageTypes"

              title="アップロード"
              width="240px"
              rounded="rounded"
              >
            </team-base-input-button>
            <template
              v-if="
                errorMsgListThumbnail.length > 0 &&
                errorMsgListThumbnail.find(
                  (item) =>
                    item.attribute.indexOf('path_list') >= 0 ||
                    item.attribute.indexOf('file_name') >= 0
                )
              "
            >
              <base-message
                type="error"
                v-for="(error, i) in errorMsgListThumbnail"
                :key="i"
                :class="$style.msg_area"
                >{{ error.message }}</base-message
              >
            </template>
          </base-form-field>

          <base-form-field  name="video_path" label="動画共有" expand expanded>
            <base-input
              name="video_path"
              id="video_path"
              v-model="chat.video_path"
              :error="
                v$.chat.video_path.$invalid && v$.chat.video_path.$dirty
                  ? v$.chat.video_path.$errors[0].$message
                  : isFieldErrorMsg('video_path', errorMsgList)
              "
            />
            <div :class="$style.video_text">※くみあいアプリ内の動画もしくは、YouTubeの共有リンクを入力すると投稿に動画が設置できます。</div>
          </base-form-field>

          <base-form-field
            v-if="unionOneSpaceFlg"
            name="file_id"
            label="ユニオンワンスペースファイルID"
            expand
            :expanded="false"
          >
            <base-input
              name="file_id"
              id="file_id"
              v-model="chat.file_id"
              maxLength="36"
              :disabled="chat.type === 4"
              :error="
                v$.chat.file_id.$invalid && v$.chat.video_path.$dirty
                  ? v$.chat.file_id.$errors[0].$message
                  : isFieldErrorMsg('file_id', errorMsgList)
              "
            />
            <div :class="$style.video_text">
              ユニオンワンスペースのコンテンツを表示するためのファイルIDを入力できます。
            </div>
          </base-form-field>

          <base-form-field label="本文" name="body" expand expanded>
            <base-input-comment
              name="body"
              :showStampIcon="false"
              :showRecordIcon="false"
              :showFileIcon="false"
              @update="onTextUpdate"
              @text_changed="onChangeText"
              @text_length="getTextLength"
              :textareaValue="chat.formatted_text_web"
              :rows="10"
              :error="textErrorMessage"
              :maxlength=5000
            />
            <div :class="$style.title_text">{{text_length}}/5000</div>
            <!-- <label>確認用</label> -->
            <!-- <div>{{ chat.text }}</div> -->
            <!-- <div>{{ $sanitize(chat.text) }}</div> -->
            <!-- <div v-html="$sanitize(chat.text)"></div> -->
            <base-input-comment
              name="attached_file"
              :showStampIcon="false"
              :showRecordIcon="false"
              :showTextArea="false"
              fileIconName="upload_file.svg"
              @file_updated="onAttachedFileUpload"
              @file_delete="onAttachedFileDelete"
              @error="onFileError"
              :showTrash="true"
              textareaValue="添付ファイルを追加"
              :maxFileSize="50"
              :files="files"
              :imageTypes="imageTypes"
            />
          </base-form-field>

          <template
            v-if="
              errorMsgList.length > 0 &&
              errorMsgList.find(
                (item) =>
                  item.attribute.indexOf('path_list') >= 0 ||
                  item.attribute.indexOf('file_name') >= 0
              )
            "
          >
            <base-message
              type="error"
              v-for="(error, i) in errorMsgList"
              :key="i"
              :class="$style.msg_area"
              >{{ error.message }}</base-message
            >
          </template>
        </div>

    <div :class="$style.edit_footer">
          <div :class="$style.confirm_button_wrap">
            <base-button
              bordered
              rounded="rounded"
              width="120px"
              @mousedown.prevent="
                [
                  chat_id
                    ? $router.push(`/team/post-detail/${team_id}/${chat_id}`)
                    : $router.push(`/team/home/${team_id}`),
                ]
              "
              >キャンセル</base-button
            >
            <base-button
              rounded="rounded"
              width="120px"
              :disabled="processing"
              @mousedown="handleSubmit"
              >設定完了</base-button
            >
          </div>
        </div>
      </div>
    <div :class="$style.spinner" v-if="processing">
      <base-icon name="spinner" size="32px" spin />
    </div>
  </section>
</template>
<script>
import BaseInput from "@/components/base/BaseInput/BaseInput";
import BaseInputComment from "@/components/base/BaseInputComment/BaseInputComment";
import BaseFormField from "@/components/base/BaseFormField/BaseFormField";
import BaseRadio from "@/components/base/BaseRadio/BaseRadio";
import BaseDatePicker from "@/components/base/BaseDatePicker/BaseDatePicker";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect";
import BaseRadioColor from "@/components/base/BaseRadioColor/BaseRadioColor";
// import BaseFormAction from "@/components/base/BaseFormAction/BaseFormAction";
import BaseButton from "@/components/base/BaseButton/BaseButton";
import BaseSelectTaggable from "@/components/base/BaseSelectTaggable/BaseSelectTaggable";
import BaseMessage from "@/components/base/BaseMessage/BaseMessage";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon";
import { isImage, getBase64FromDataUrl } from "../../utils/helper";
import useVuelidate from "@vuelidate/core";
import { helpers, required, maxLength, minLength, maxValue} from "@vuelidate/validators";
import { isFieldErrorMsg } from "@/helper/validator.js";
import { validateYoutube } from "@/utils/helper";
import { validateTags } from "@/utils/helper";
import TeamBaseInputButton from "@/pages/team/components/TeamBaseInputButton";

export default {
  name: "TeamPostCreate",
  components: {
    /*BaseFormAction, */ BaseRadioColor,
    BaseSelect,
    BaseDatePicker,
    BaseRadio,
    BaseFormField,
    BaseInput,
    BaseInputComment,
    BaseButton,
    BaseSelectTaggable,
    BaseMessage,
    BaseIcon,
    TeamBaseInputButton,
  },
  props: {
    team_id: {
      type: Number,
      required: true,
      default: null,
    },
    chat_id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  mounted() {
    // もし横幅が768px以下ならheaderColor: "yellow"だが、それ以上ならheaderColor: "white"にする
    if (window.innerWidth < 768) {
      this.$router.app.$emit("page-updated", {
        showSetting: false,
        back: `/team/home/${this.team_id}`,
        headerColor: "yellow",
        bgColor: "white",
        showFooter: false,
        showTabbar: false,
        showPcBackBtn: true,
      });
    } else {
      this.$router.app.$emit("page-updated", {
        showSetting: false,
        back: `/team/home/${this.team_id}`,
        headerColor: "white",
        bgColor: "white",
        showFooter: false,
        showTabbar: false,
        showPcBackBtn: true,
      });
    }
  },
  setup: function () {
    return { v$: useVuelidate() };
  },
  validations: function () {
    return {
      text_length: {
        maxValue:  helpers.withMessage(
          "本文は、5000文字以下にしてください。",
          maxValue(5000)
        ),
        $autoDirty: true,
      },
      chat: {
        title: {
          required: helpers.withMessage(
            "タイトルは、必ず指定してください。",
            required
          ),
          minLength: helpers.withMessage(
            "最低1文字以上入力してください。",
            minLength(1)
          ),
          $autoDirty: true,
        },
        plain_text: {
          required: helpers.withMessage(
            "本文テキストは、必ず指定してください。",
            required
          ),
          $autoDirty: true,
        },
        formatted_text_web: {
          $autoDirty: true,
          validateTags:helpers.withMessage(
            "不正なタグが含まれています。",
            validateTags
          )
        },
        video_path: {
          validateYoutube: helpers.withMessage(
            "動画共有は、YouTubeまたは講座のURLを入力してください。",
            validateYoutube
          ),
          videoConflict: helpers.withMessage(
            '動画共有とファイルIDはどちらか一方のみ入力してください。',
            (value, siblings) => {
              return !(value != '' && siblings.file_id != '');
            }
          ),
          $autoDirty: true,
        },
        file_id: {
          minLength: helpers.withMessage(
            'ファイルIDは36文字である必要があります。',
            minLength(36)
          ),
          maxLength: helpers.withMessage(
            'ファイルIDは36文字である必要があります。',
            maxLength(36)
          ),
          fileConflict: helpers.withMessage(
            '動画共有とファイルIDはどちらか一方のみ入力してください。',
            (value, siblings) => {
              return !(value != '' && siblings.video_path != '');
            }
          ),
          $autoDirty: true
        },
      },
    };
  },
  data() {
    return {
      publish: "publish",
      commentView: "public",
      toOptions: [
        { value: "1", label: "チームメンバー全員" },
        { value: "2", label: "個人" },
        { value: "3", label: "共同管理者" },
      ],
      colorValues: [
        "255,255,255",
        "249,249,249",
        "245,220,220",
        "241,245,220",
        "222,245,220",
        "220,242,245",
        "226,220,245",
        "245,220,237",
      ],
      chat: {
        team_id: this.team_id,
        chat_id: this.chat_id,
        type: 1, // 投稿
        title: "",
        plain_text: "",
        formatted_text_web: "",
        service_link_text: "",
        video_path: "",
        file_id: "",
        disp_ptn: "",
        open_flg: true,
        start_datetime: "",
        end_datetime: "",
        auth_type: "1",
        background_color: "255,255,255",
        pin_flg: false,
        path_list: [],
        comment_view_type: 1,
      },
      text_length: 0,
      to_personal_options: [],
      to_personal_selected: [],
      to_personal_original: [],
      to_personal_loading: true,
      input_start_datetime: "",
      input_end_datetime: "",
      response_errors: {
        title: "",
        text: "",
        video_path: "",
        start_datetime: "",
        end_datetime: "",
        to_personal: "",
      },
      files: [],
      cover_image_files: [],
      imageTypes: [
        "image", // 登録済みの画像形式
        "image/apng", // APNG
        "image/avif", // AVIF
        "image/gif", // GIF
        "image/jpeg", // JPEG
        "image/png", // PNG
        "image/svg+xml", // SVG
        "image/webp", // WebP
      ],
      errorMsgList: [],
      errorMsgListThumbnail: [],
      processing: false,
      removed_attachment_ids: [],
      unionOneSpaceFlg: false,
    };
  },
  created: async function () {
    this.processing = true;
    await this.getDetail();
    this.getUserList();
    await this.checkUnionOneSpace()
    return;
  },
  methods: {
    isFieldErrorMsg,
    async getDetail() {
      if (!this.chat_id) return;

      this.$axios
        .get("/api/" + this.$constants.API_VERSION + "/team/chat/info", {
          params: {
            chat_id: this.chat_id,
          },
        })
        .then((response) => {
          const chat = response.data.value;
          this.chat.title = chat.title;

          this.chat.plain_text = chat.text;
          this.chat.formatted_text_web = chat.formatted_text_web;
          this.chat.service_link_text = chat.service_link_text;
          this.chat.video_path = chat.display_video_path;
          this.chat.file_id = chat.file_id;
          this.chat.disp_ptn = chat.disp_ptn;

          this.chat.type = chat.type;
          this.chat.start_datetime = chat.start_datetime;
          this.input_start_datetime = this.$dayjs(chat.start_datetime).format(
            "YYYY-MM-DDTHH:mm"
          );
          this.chat.end_datetime = chat.end_datetime;
          this.input_end_datetime = this.$dayjs(chat.end_datetime).format(
            "YYYY-MM-DDTHH:mm"
          );
          this.chat.auth_type = chat.auth_type;
          this.chat.background_color = chat.background_color;
          this.chat.open_flg = chat.open_flg;
          this.chat.pin_flg = chat.pin_flg;
          if (chat.open_flg) {
            if (chat.start_datetime || chat.end_datetime) {
              this.publish = "with-limit";
            } else {
              this.publish = "publish";
            }
          } else {
            this.publish = "private";
          }
          if (chat.comment_view_type) {
            this.commentView = "public";
          } else {
            this.commentView = "private";
          }
          if (
            chat.path &&
            0 < chat.path.length &&
            chat.image_url &&
            0 < chat.image_url.length &&
            !['junion/post.jpg', 'junion/vote.jpg'].includes(chat.path)
          ) {
            this.cover_image_files.push({
              url: chat.image_url,
              type: "image",
              name: "",
              is_new: false,
            });
          }
          chat.path_list.forEach((file) => {
            const split = file.path.split("/");
            const reverse = split.reverse();
            this.files.push({
              id: file.id,
              type: file.file_type,
              name: reverse[0],
              url: file.file_url,
              is_new: false,
            });
          });
        });
    },

    async getUserList() {
      if (!this.team_id) return;

      this.$axios
        .get("/api/" + this.$constants.API_VERSION + "/user/list", {
          params: {
            team_id: this.team_id,
            display_name_flg: true,
            user_team_status: "approved,no_approval_required",
          },
        })
        .then(async (response) => {
          const userList = response.data.value;

          if (userList) {
            userList.forEach((user) => {
              this.to_personal_options.push({
                id: user.user_id,
                label: user.nickname
                  ? user.nickname
                  : user.name
                  ? user.name
                  : user.juid,
              });
            });
          }
          this.getDetaillUser();
          this.to_personal_loading = false;
        })
        .finally(() => {
          this.processing = false;
        });
    },
    async getDetaillUser() {
      if (!this.chat_id) return;

      this.$axios
        .get("/api/" + this.$constants.API_VERSION + "/team/chat/user/list", {
          params: {
            chat_id: this.chat_id,
            auth_flg: "1",
          },
        })
        .then((response) => {
          const userList = response.data.value.comments;

          if (userList) {
            userList.forEach((user) => {
              let label = "";
              this.to_personal_options.forEach((option) => {
                if (option.id == user.user_id) {
                  label = option.label;
                }
              });
              this.to_personal_selected.push({
                id: user.user_id,
                label: label,
              });
              this.to_personal_original.push({
                id: user.user_id,
                label: label,
              });
            });
          }
        });
    },
    async checkUnionOneSpace() {
      if (!this.team_id) return
      try {
      const response = await this.$axios.get(
        '/api/' + this.$constants.API_VERSION + '/team/service/check',
        {
          params: {
            team_id: this.team_id,
            service_code: 'uos',
          },
        }
      )
      this.unionOneSpaceFlg = response.data.value.is_linked
      } catch (error) {
        console.error('エラーが発生しました ', error)
        this.unionOneSpaceFlg = false
      }
    },
    onChangePublish(value) {
      if (value === "publish") {
        // 公開
        this.chat.open_flg = true;
        this.chat.start_datetime = "";
        this.chat.end_datetime = "";
      } else if (value === "private") {
        // 非公開
        this.chat.open_flg = false;
        this.chat.start_datetime = "";
        this.chat.end_datetime = "";
      } else if (value === "with-limit") {
        // 期間指定(期間指定で公開)
        this.chat.open_flg = true;
        this.chat.start_datetime = this.input_start_datetime;
        this.chat.end_datetime = this.input_end_datetime;
      }
    },
    onChangeCommentView(value) {
      if (value === "public") {
        this.chat.comment_view_type = 1;
      } else if (value === "private") {
        this.chat.comment_view_type = 0;
      }
    },
    onTextUpdate(text) {
      this.chat.formatted_text_web = text;
    },
    onChangeText(text) {
      this.chat.plain_text = text;
    },
    getTextLength(value) {
      this.text_length = value
      // console.log(value)
      // if (value.match(/\n/)) {
      //   console.log("LF")
      // }
      // if (value.length > 0 && value.slice(-2).match(/\n\s/)) {
      //   console.log("end enter space")
      // }

      // this.chat.text_value = value;
    },
    onAttachedFileUpload([data_url, file]) {
      this.errorMsgList = [];
      if (5 <= this.files.length) {
        this.errorMsgList.push({
          attribute: "path_list",
          message: "添付ファイルは５件まで登録できます。",
        });
        return;
      }

      this.files.push({
        id: "",
        type: file.type,
        name: file.name,
        url: data_url,
        is_new: true,
      });
    },
    onCoverImageDelete(file, index) {
      file;
      index;
      this.errorMsgListThumbnail = [];
      this.cover_image_files = [];
    },
    onAttachedFileDelete(file, index) {
      const deleted_file = this.files.splice(index, 1)[0];

      if(!deleted_file.is_new){
        this.removed_attachment_ids.push(deleted_file.id);
      }
    },
    onFileError(errors) {
      this.errorMsgList = [];
      if (errors.filesize) {
        this.errorMsgList.push({
          attribute: "path_list",
          message: "添付ファイルのファイルサイズの上限は50Mbyteです。",
        });
      }
    },
    onCoverImageUpload([data_url, file]) {
      this.errorMsgListThumbnail = [];
      this.cover_image_files = [];
      this.cover_image_files.push({
        id: "",
        type: file.type,
        name: file.name,
        url: data_url,
        is_new: true,
      });
    },
    onCoverImageError(errors) {
      this.errorMsgListThumbnail = [];
      if (errors.filesize) {
        this.errorMsgListThumbnail.push({
          attribute: "path_list",
          message: "画像ファイルのファイルサイズの上限は50Mbyteです。",
        });
      }
    },
    addPostFile() {
      // 設定前に初期化
      this.chat.path = null;
      this.chat.path_list = [];

      this.files.forEach((file) => {
        if (file.is_new) {
          this.chat.path_list.push({
            path: getBase64FromDataUrl(file.url),
            file_name: file.name,
            file_type: isImage(file.type) ? "image" : "file",
          });
        }
      });
      if (0 < this.cover_image_files.length) {
        if (this.cover_image_files[0].is_new) {
          this.chat.path = getBase64FromDataUrl(this.cover_image_files[0].url);
          this.chat.file_name = this.cover_image_files[0].name;
        } else {
          delete this.chat["path"];
        }
      } else {
        this.chat.path = null;
      }
    },
    validateToPersonal() {
      if (this.chat.auth_type == "2") {
        if (this.to_personal_selected.length == 0) {
          this.errorMsgList.push({
            attribute: "to_personal",
            message: "宛先が個人の時はユーザーを、必ず指定してください。",
          });
          return false;
        } else {
          return true;
        }
      }
      return true;
    },
    validateWithLimit() {
      if (this.publish == "with-limit") {
        if (
          0 == this.input_start_datetime.length &&
          0 == this.input_end_datetime.length
        ) {
          this.errorMsgList.push(
            {
              attribute: "start_datetime",
              message:
                "公開設定が期間を指定の時は、開始日時もしくは終了日時を、必ず指定してください。",
            },
            {
              attribute: "end_datetime",
              message:
                "公開設定が期間を指定の時は、開始日時もしくは終了日時を、必ず指定してください。",
            }
          );
          return false;
        }
      }
      return true;
    },
    async handleSubmit() {
      // フロント側でエラーチェック
      this.v$.$validate();
      this.errorMsgList = [];

      let invalid = !this.validateToPersonal();
      invalid = this.validateWithLimit() ? invalid : true;
      if (this.v$.$invalid || invalid) {
        return;
      }

      // ２度押し防止
      if (this.processing) {
        return;
      }
      this.processing = true;
      this.onChangePublish(this.publish);
      this.onChangeCommentView(this.commentView)
      this.addPostFile();

      //添付ファイルを削除するAPI
      const promises = this.removed_attachment_ids.map(async id => {
        return this.$axios.delete("/api/" + this.$constants.API_VERSION + "/attachment/delete", {params:{id}})
      })
      await Promise.all(promises).then(() => {
        this.removed_attachment_ids = [];
      });

      if (this.chat.disp_ptn !== '01') {
        this.chat.disp_ptn = this.chat.file_id ? '02' : null;
      }

      // 組織の投稿登録・更新API
      if (this.chat.chat_id) {
        // 更新
        const is_new_post = false
        this.$axios
          .put("/api/" + this.$constants.API_VERSION + "/team/chat/update", this.chat)
          .then(async (response) => {
            this.chat.chat_id = response.data.value.chat_id;
            // ファイル情報クリア
            this.path = null;
            this.path_list = [];
            // 個人宛先登録
            await this.postChatFavorite(is_new_post);
            this.processing = false;
            // 画面遷移
            this.$router.push(
              `/team/post-detail/${this.team_id}/${this.chat.chat_id}`
            );
          })
          .catch((err) => {
            if (err.response.status === 400) {
              this.errorMsgList = err.response.data.value.errors;
            }
            this.processing = false;
          });
      } else {
        // 新規登録
        const is_new_post = true
        this.$axios
          .post("/api/" + this.$constants.API_VERSION + "/team/chat/registration", this.chat)
          .then(async (response) => {
            this.chat.chat_id = response.data.value.chat_id;
            // ファイル情報クリア
            this.path = null;
            this.path_list = [];
            // 個人宛先登録
            await this.postChatFavorite(is_new_post);
            this.processing = false;
            // 画面遷移
            this.$router.push(
              `/team/post-detail/${this.team_id}/${this.chat.chat_id}`
            );
          })
          .catch((err) => {
            if (err.response.status === 400) {
              this.errorMsgList = err.response.data.value.errors;
            }
            this.processing = false;
          });
      }
    },
    async postChatFavorite(is_new_post) {
      let chat_users = [];
      // 追加するユーザー
      this.to_personal_selected.forEach((user) => {
        chat_users.push({
          chat_id: this.chat.chat_id,
          user_id: user.id,
          auth_flg: "1",
        });
      });
      // 削除するユーザー
      this.to_personal_original.forEach((original) => {
        if (!this.to_personal_selected.find((user) => user.id == original.id)) {
          chat_users.push({
            chat_id: this.chat.chat_id,
            user_id: original.id,
            auth_flg: "0",
          });
        }
      });
      await this.$axios
        .post("/api/" + this.$constants.API_VERSION + "/team/chat/favorite", { chat_users: chat_users, is_new_post: is_new_post })
        .then(() => {})
        .catch((err) => {
          if (err.response.status === 400) {
            this.errorMsgList = err.response.data.value.errors;
          }
        });
    },
  },
  computed: {
    // モバイル判定
    isMobile() {
      if (window.innerWidth < 768) {
        return true;
      } else {
        return false;
      }
    },

    // 本文のエラーメッセージ
    textErrorMessage(){
      if (this.v$.chat.plain_text.$invalid && this.v$.chat.plain_text.$dirty) {
        return this.v$.chat.plain_text.$errors[0].$message;
      } else if(this.v$.chat.formatted_text_web.$invalid && this.v$.chat.formatted_text_web.$dirty){
        return this.v$.chat.formatted_text_web.$errors[0].$message;
      } else if(this.v$.text_length.$invalid && this.v$.text_length.$dirty){
        return this.v$.text_length.$errors[0].$message;
      } else {
        return this.isFieldErrorMsg('plain_text', this.errorMsgList) + this.isFieldErrorMsg('formatted_text_web', this.errorMsgList);
      }
    },
  },
};
</script>
<style lang="scss" module>
.contents {
  background-color: $keyWhite;
  width: 100%;
  margin: 0 30px;

  @include mobile_only() {
    width: 375px;
  }
}
.title_text {
  text-align: right;
  font-size: small;
  color: #b7b7b7;
  @media screen and (max-width: 768px) {
    width: 300px;
  }
}
.accordion_content_wrap {
  &.disclosure {
    span {
      font-size: 16px;
    }
    & > :first-child {
      margin-bottom: 8px;
    }
    & > :last-child {
      margin-top: 12px;
    }
  }
}
.radio_row {
  display:inline-flex;
  }
  .radio_margin {
    padding-left:50px;
  }
.thumbnail {
  margin-left: 2rem;
  @media screen and (max-width: 768px) {
    width: 300px;
    margin: 0 auto;
  }
}
.video_text {
  font-size: small;
  margin-top: 1rem;
  margin-left: 2rem;
  @media screen and (max-width: 768px) {
    width: 300px;
    margin-left: 0;
  }
}
.edit_footer {
  display: flex;
  position: fixed;
  width: 100%;
  height: 85px;
  bottom: 0;
  left: 0;
  background: $keyYellow;
  z-index: 12;

  @include mobile(){
    box-sizing: border-box;
    border-top: 1px solid $borderLiteGray;
    position: fixed;
  }

  .confirm_button_wrap {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    & > :first-child {
      margin-right: 20px;
    }
  }
}

.form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


.to_select{
  select {
    width: 100%;
    margin-bottom: 15px;
  }
}

.range_label {
  width: 40%;
  margin-top: 4px;
  margin-bottom: 4px;
}

.msg_area {
  width: 100% !important;
  margin-top: 10px;
}

.spinner {
  position: absolute;
  top: 50%
}

.post_title {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.with_limit {
  display: flex;
  gap: 5%;
  @media screen and (max-width: 768px) {
    display: block;
  }
  .time_from, .time_to {
    width: 50%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}

$textSize_Error: 13px;
$textSpecing_Error: 4px;
$textColor_Error: #ff0000;

.error_txt {
  font-size: $textSize_Error;
  margin: $textSpecing_Error 0 0 0;
  color: $textColor_Error;
  white-space: pre-wrap;
}

</style>
