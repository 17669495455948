<template>
  <div :class="$style.wrapper">
    <div v-if="!deletion_success">
      <app-prev-button @click="goPreviousPage()" :class="$style.only_sp" :no-mobile="true"/>

      <base-message type="error" :class="$style.error_txt" v-if="error_message">{{error_message}}</base-message>

      <h1 :class="$style.h1">退会手続き</h1>

      <section :class="$style.section">
        <h2 :class="$style.h2">退会にあたっての確認事項</h2>
        <p>下記項目に同意いただき、退会ボタンを押してください。</p>
      </section>

      <section :class="[$style.section, $style.checkbox]">
        <base-check-box
          name="check0"
          v-model="inputCheckBox[0]"
        >
          <span>
            チームへの投稿は削除されません。
            <small>
              ※削除が必要な場合は、<br :class="$style.only_sp" />ご自身で削除の上、退会手続きにお進みください。
            </small>
          </span>
        </base-check-box>

        <base-check-box
          name="check1"
          v-model="inputCheckBox[1]"
        >
          <span>
            退会手続き後、アカウントの復旧はできません。
          </span>
        </base-check-box>

        <base-check-box
          name="check2"
          v-model="inputCheckBox[2]"
        >
          <span>
            再登録の場合は、<br :class="$style.only_sp" />同じメールアドレスや電話番号をお使いいただけます。
          </span>
        </base-check-box>
      </section>

      <section :class="$style.section">
        <base-button
          :class="[all_checked ? this.$style.btn_active : '', $style.profile_action_button]"
          width="200px"
          rounded="rounded"
          color="white"
          bordered
          @click="submitDelete()"
        >はい、退会します</base-button>

        <base-button
          :class="$style.profile_action_button"
          width="200px"
          rounded="rounded"
          @click="goPreviousPage()"
        >いいえ、退会しません</base-button>
      </section>
    </div>

    <div v-if="deletion_success">
      <h1 :class="$style.h1">退会完了</h1>

      <section :class="$style.section">
        <p>
          退会が完了しました<br/>
          ご利用ありがとうございました。
        </p>
      </section>
      <section :class="$style.section">
        <base-button
          :class="[all_checked ? this.$style.btn_active : '', $style.profile_action_button]"
          rounded="rounded"
          color="white"
          bordered
          @click="routeAfterDeletion()"
        >くみあいアプリTOPページへ</base-button>
      </section>
    </div>
  </div>
</template>

<script>
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'
import BaseCheckBox from '@/components/base/BaseCheckBox/BaseCheckBox'
import BaseButton from "@/components/base/BaseButton/BaseButton";
import BaseMessage from '@/components/base/BaseMessage/BaseMessage'
import VueCookies from 'vue-cookies'
const cookies = VueCookies

export default {
  name: 'OpenDeleteAccount',
  components: {
    AppPrevButton,
    BaseCheckBox,
    BaseButton,
    BaseMessage
  },
  mounted() {
  },
  data() {
    return {
      deletion_success: false,
      inputCheckBox: [false,false,false],
      all_checked: false,
      error_message: "",
      processing: false,
    }
  },
  watch: {
    inputCheckBox: function(arr) {
      // チェックボックスがすべて選択されているか確認
      if (arr.filter(n => n === true).length > 2) {
        this.all_checked = true
      } else {
        this.all_checked = false
      }
    }
  },
  methods: {
    submitDelete: async function() {
      this.error_message = '';

      // ２度押し防止
      if(this.processing){
        return
      }
      this.processing = true

      await this.$axios
        .delete('/api/' + this.$constants.API_VERSION + '/user/delete_account')
        .then((res) => {
          // console.log(res.data.test)

          this.processing = false
          if (res && res.status === 200) {
            this.deletion_success = true
            cookies.remove('auth_info');
          } else {
            this.error_message = 'エラーが発生しました。時間をおいて再度お試しください。'
            this.deletion_success = false
            this.scrollToTop()
          }
        })
        .catch(() => {
          this.processing = false
          this.error_message = 'エラーが発生しました。時間をおいて再度お試しください。'
          this.deletion_success = false
          this.scrollToTop()
        })
    },
    goPreviousPage(){
      this.$router.go(-1)
    },
    routeAfterDeletion() {
      this.$router.push(`/open/login`)
    },
    scrollToTop: function(){
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }
  }
}
</script>

<style lang="scss" module>
.wrapper {
  color: $keyBlack;
  background-color: $openBg;
  text-align: left;
  margin: 0 auto;
  box-sizing: border-box;
  max-width: 1080px;
  padding: 68px;

  @include mobile() {
    width: 375px;
    padding: 42px 32px;
  }

  * {
    margin: 0;
  }

  .error_txt {
    margin-bottom: 30px;
  }

  .only_sp {
    @include pc() {
      display: none;
    }
  }

  .h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 52px;

    @include mobile() {
      font-size: 24px;
    }
  }

  > div {
    > .section {
      @include mobile() {
        font-size: 11px;
      }

      &:first-of-type {
        @include pc() {
          font-size: 13px;
        }
      }

      &:last-of-type {
        display: flex;
        flex-flow: column;
        align-items: center;
        gap: 32px;
        @include mobile() {
          gap: 20px;
        }
      }

      &:not(:last-child) {
        margin-bottom: 52px;

        @include mobile() {
          margin-bottom: 40px;
        }
      }

      .h2 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;

        @include mobile() {
          font-size: 15px;
          margin-bottom: 0;
        }
      }

      .profile_action_button {
        @include pc() {
          border-radius: 0;
          border-width: 1px;
          width: 310px !important;
          height: 50px !important;
          font-size: 16px;
        }

        &:first-of-type {
          pointer-events: none;
          opacity: .25;
          transition: opacity .4s;

          &.btn_active {
            pointer-events: all;
            opacity: 1;
          }
        }
      }

      &.checkbox {
        label {
          align-items: start;
          margin-bottom: 18px;
          min-height: 32px;

          @include mobile() {
            font-size: 11px;
          }

          small {
            display: block;

            @include mobile() {
              margin-left: 1em;
              text-indent: -1em;
            }
          }

          > span {
            @include mobile() {
              padding-top: 2px;
            }

            &:first-of-type {
              padding-top: 0;
              flex-shrink: 0;
            }
          }
        }
      }
    }
  }
}
</style>
