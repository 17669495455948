<template>
  <div :class="[$style.member_manage_bg1, { [$style.manage_member]: initLoading }]">
    <div :class="$style.member_manage_bg2" v-if="!initLoading">
      <app-prev-button margin="0px 10px" padding="10px"
        @click="$router.push(team_id ? `/team/home/${team_id}` : `/home/home`)" />
      <section :class="$style.member_manage">
        <div :class="$style.member_manage_header">
          <img :src="team.badge_image" :style="imgStyleObject" />
          <div :class="$style.header_texts">
            <h1 :class="$style.member_manage_title">{{ team.name }}</h1>
            <p :class="$style.member_manage_summary">
              <span :class="$style.member_manage_summary_members">
                <span>アクティブメンバー: {{ team.member_count }}名</span>
                <span>管理者: {{ team.admin_count }}名</span>
                <span :class="$style.member_manage_invited">承認待ち: {{ team.approval_pending_count }}名</span>
              </span>
              <br>
              <span :class="$style.member_manage_summary_team_id">Team-ID: {{ team_id }}</span>
            </p>
          </div>
        </div>

        <hr />

        <section :class="$style.main_area">
          <div :class="$style.search_area">
            <base-accordion title="メンバー検索" type="borderless" bold expanded>
              <div :class="$style.search_container">

                <div :class="$style.total_area">
                  <p :class="$style.member_count_label">
                    {{ total }} 名
                  </p>
                </div>

                <div :class="$style.checkbox_area">
                  <label :class="$style.form_label">
                    探したいメンバーのニックネームか氏名かju-IDを入力してください
                  </label>
                  <base-input v-model="searchText" look="outline1" :class='$style.custom_base_input' />
                  <div :class="$style.checkbox_container">
                    <base-check-box v-for="item in checkboxItems" :key="item.value" :value="item.value"
                      v-model="searchStatuses" :fontSize="'14px'">
                      {{ item.label }}
                    </base-check-box>
                  </div>
                </div>

                <div :class="$style.block_area">
                  <base-button width="160px" rounded="rounded" height="40px" @click="searchMember">検索</base-button>
                </div>

              </div>
            </base-accordion>
          </div>

          <template v-if="total > 0">
            <div :class="$style.select_block" v-if="is_approval == true || is_remove_member == true">
              <label :class="$style.select_label">
                チームメンバーを承認する、メンバーから除く
              </label>
              <p :class="$style.select_description">
                メンバーを承認、メンバーから除く場合は、「承認する」または「メンバーから除く」を選択してから、対象メンバーにチェックをして
                「実行」ボタンを押下してください。共同管理者の場合は、一括除名は行えません。
              </p>
              <base-select v-model="selectedRole" :options="selectOptions" look="outline1" placeholder="未選択"
                :width="'310px'" />
            </div>

            <div :class="$style.button_wrapper">
              <base-button v-if="selectedRole !== 'not_selected'"
                :disabled="!selectedRole || selectedItems.length === 0" display="inline-block" width="120px"
                height="40px" size="m" rounded="rounded" bgColor="green" bordered margin="20px 0"
                @click="executeAction">実行
              </base-button>
            </div>

            <div :class="$style.list_table">
              <app-table-list :col-defs="colDefs" :row-data="members" :page="currentPage" :page-data-num="limit"
                :total-record="total" :current-user-id="currentUserId" :fetch-mode="true" :selected="selectedItems"
                :sort-key="currentSort.key" :sort-asc="currentSort.order === 'asc'" :noSort=true :row-key="'user_id'"
                :disabled-items="disabledItems" @select="handleSelect" @page-no-click="handlePageChange"
                @cell-click="handleClickUser" @sort-click="handleSort">
                <template v-slot:execute-button>
                  <div :class="$style.button_wrapper">
                    <base-button v-if="selectedRole !== 'not_selected'" @click="executeAction"
                      :disabled="!selectedRole || selectedItems.length === 0" display="inline-block" width="120px"
                      height="40px" size="m" rounded="rounded" bgColor="green" bordered margin="10px 0">実行</base-button>
                  </div>
                </template>
              </app-table-list>
            </div>

            <div :class="[$style.csv_download_container]">
              <div :class="$style.block_area">
                <base-button width="160px" rounded="rounded" height="40px" @click="csvDownload">CSVダウンロード</base-button>
              </div>
            </div>
          </template>
          <div v-if="hasSearched && total === 0" :class="$style.no_results">
            検索結果が0件です
          </div>

        </section>
        <app-scroll-button />
      </section>
    </div>
    <div v-if="initLoading" :class="$style.manage_member_loading_area">
      <base-icon name="spinner" size="32px" spin />
    </div>
  </div>
</template>
<script>
import BaseAccordion from "@/components/base/BaseAccordion/BaseAccordion"
import BaseButton from "@/components/base/BaseButton/BaseButton"
import BaseCheckBox from '@/components/base/BaseCheckBox/BaseCheckBox'
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import BaseInput from '@/components/base/BaseInput/BaseInput'
import BaseSelect from "@/components/base/BaseSelect/BaseSelect";
import AppScrollButton from '@/components/app/AppScrollButton/AppScrollButton'
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'
import AppTableList from '@/components/app/ManageMemberTableList/AppTableList'
import { mapGetters } from 'vuex'

export default {
  name: 'TeamManageMember',
  components: { BaseAccordion, BaseButton, BaseCheckBox, BaseIcon, BaseInput, BaseSelect, AppPrevButton, AppScrollButton, AppTableList },
  props: {
    team_id: {
      type: Number,
      required: true
    }
  },
  mounted() {
    this.$router.app.$emit('page-updated', {
      pageTitle: 'チームメンバー（管理）',
      back: this.team_id ? `/team/home/${this.team_id}` : `/home/home`,
      showSetting: false,
      headerColor: 'yellow',
      bgColor: 'white',
      showFooter: true
    })
  },
  data() {
    return {
      showSearch: false,
      team: {
        name: "",
        admin_count: 0,
        approval_pending_count: 0,
        team_id: 0,
        member_count: 0,
        auth_type: "",
        badge_image: ""
      },
      members: [],
      before_approval_member: [],
      not_approval_member: [],
      bulk_approval_member: [],
      is_approval: false,
      is_remove_member: false,

      searchText: "",

      checkboxItems: [
        { label: '管理者', value: '1' },
        { label: 'メンバー', value: '2' },
        { label: '承認待ち', value: '3' },
        { label: '過去メンバー', value: '4' },
        { label: '非承認メンバー', value: '5' },
      ],
      searchStatuses: ['1', '2', '3'],

      selectedRole: 'not_selected',
      selectOptions: [],
      selectedItems: [],
      disabledItems: [],

      colDefs: [
        {
          key: "name",
          label: "名前",
          sortable: true
        },
        {
          key: "nickname",
          label: "ニックネーム",
          sortable: true,
          clickable: true,
          textStyle: 'link'
        },
        {
          key: "status",
          label: "状態",
          sortable: true
        },
        {
          key: "participation_date",
          label: "組織参加日",
          sortable: true
        },
      ],
      sortKey: "",
      currentSort: {
        key: null,
        order: null // 押下ごとに null → asc → desc と変わる
      },
      total: 0,
      currentPage: 1,
      limit: 100,

      hasSearched: false,
      err_message: '',
      initLoading: true,
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.limit)
    },
    offset() {
      return (this.currentPage - 1) * this.limit
    },
    imgStyleObject() {
      return {
        'width': '120px',
        'height': '120px',
        'border-radius': '50%',
        'object-fit': 'cover',
        'border': 'solid 1.5pt #ccc',
      }
    },
    ...mapGetters('auth', ['getUserId']),
    currentUserId() {
      return Number(this.getUserId)
    }
  },
  async created() {
    try {
      const [teamResponse] = await Promise.all([
        this.fetchTeam(),
        this.searchMember()
      ]);

      this.team = {
        name: teamResponse.name,
        admin_count: teamResponse.admin_count,
        approval_pending_count: teamResponse.approval_pending_count,
        team_id: teamResponse.team_id,
        member_count: teamResponse.member_count,
        auth_type: teamResponse.auth_type,
        badge_image: teamResponse.badge_image
      };
    } catch (error) {
      console.error('エラーが発生しました:', error);
    } finally {
      this.initLoading = false;
    }
  },
  methods: {
    fetchTeam: async function () {
      let team = {}
      await this.$axios.get('/api/' + this.$constants.API_VERSION + '/team/info', {
        params: {
          "team_id": this.team_id
        }
      }).then((res) => {
        team = res.data.value.team
      }).catch((err) => {
        this.$router.push('/error/' + err.response.status)
      })
      return team
    },
    async fetchMembers() {
      this.initLoading = true;
      this.disabledItems = ['*'];
      this.selectedRole = 'not_selected';
      try {
        const response = await this.$axios.get('/api/' + this.$constants.API_VERSION + '/user/member_list', {
          params: {
            team_id: this.team_id,
            text: this.searchText,
            status: this.searchStatuses.length > 0 ? this.searchStatuses : undefined,
            sort: this.currentSort.key,
            direction: this.currentSort.order,
            limit: this.limit,
            offset: (this.currentPage - 1) * this.limit
          }
        });

        if (response.data.status === 'success') {
          this.is_approval = response.data.value.is_approval;
          this.is_remove_member = response.data.value.is_remove_member;
          this.members = response.data.value.user_list;
          this.total = response.data.value.count.total;

          this.selectOptions = [
            { label: '未選択', value: 'not_selected' }
          ];
          if (this.is_approval) {
            this.selectOptions.push({ label: '承認', value: 'approve' });
          } else {
            this.selectOptions.push({ label: '承認', value: 'approve', disabled: true });
          }

          if (this.is_remove_member) {
            this.selectOptions.push({ label: 'メンバーから除く', value: 'withdrawal' });
          } else {
            this.selectOptions.push({ label: 'メンバーから除く', value: 'withdrawal', disabled: true });
          }
        }
      } catch (error) {
        console.error('エラーが発生しました:', error);
      } finally {
        this.initLoading = false;
      }
    },
    async searchMember() {
      this.currentPage = 1;  // ページを1に戻す
      this.currentSort = { key: null, order: null };
      await this.fetchMembers();
      this.hasSearched = true;
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchMembers();
    },
    async executeAction() {
      if (!this.selectedRole || this.selectedItems.length === 0) return;
      const messages = {
        approve: '選択しているユーザーを一括承認します。よろしいですか？',
        withdrawal: '選択しているユーザーを一括でメンバーから除きます。よろしいですか？'
      };
      if (!confirm(messages[this.selectedRole])) return;
      const api_url = {
        approve: '/api/' + this.$constants.API_VERSION + '/team/user/approve',
        withdrawal: '/api/' + this.$constants.API_VERSION + '/team/user/bulk_remove'
      };
      if (!api_url[this.selectedRole]) return;
      try {
        await this.$axios.post(api_url[this.selectedRole], {
          user_id_list: this.selectedItems,
          team_id: this.team_id
        });
        this.reload()
      } catch (error) {
        console.error('エラーが発生しました:', error);
      }
    },
    handleClickUser(cellData) {
      if (Number(cellData.rowDat.user_id) === Number(this.currentUserId)) {
        return;
      }
      const url = `/team/${this.team_id}/member-profile/${cellData.rowDat.user_id}`;
      this.$router.push(url);
    },
    handleSelect(selected) {
      this.selectedItems = selected;
    },
    handleSort({ key }) {
      if (this.currentSort.key === key) {
        // 押下ごとに null → asc → desc と変わる
        if (!this.currentSort.order) {
          this.currentSort.order = 'asc';
        } else if (this.currentSort.order === 'asc') {
          this.currentSort.order = 'desc';
        } else {
          this.currentSort.key = null;
          this.currentSort.order = null;
        }
      } else {
        // 異なる列を押下した場合、前の列をリセットして新しい列をascでソート
        this.currentSort.key = key;
        this.currentSort.order = 'asc';
      }
      this.handlePageChange(this.currentPage);
    },
    csvDownload: async function () {
      try {
        const response = await this.$axios.get('/api/' + this.$constants.API_VERSION + '/user/download', {
          params: {
            team_id: this.team_id,
            text: this.searchText,
            status: this.searchStatuses.length > 0 ? this.searchStatuses : undefined,
            sort: this.currentSort.key,
            direction: this.currentSort.order,
          },
          responseType: 'blob'
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const csvName = `${this.team.name} チームメンバー一覧.csv`;
        link.setAttribute('download', csvName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

      } catch (err) {
        this.err_message = err.response?.data?.value?.errors[0]?.message || 'ダウンロードに失敗しました';
      }
    },
    reload() {
      window.location.reload()
    },
  },
  watch: {
    selectedRole(newValue) {
      this.selectedItems = [];
      if (newValue === 'approve') {
        this.disabledItems = this.members
          .filter(member => member.status !== '承認待ち')
          .map(member => member.user_id);
      } else if (newValue === 'withdrawal') {
        this.disabledItems = this.members
          .filter(member => !['管理者', '共同管理者', 'メンバー'].includes(member.status))
          .map(member => member.user_id);
      } else if (newValue === 'not_selected') {
        this.disabledItems = ['*'];
      } else {
        this.disabledItems = [];
      }
    }
  }
}
</script>
<style lang="scss" module>
.manage_member {
  background-color: white;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
  justify-content: center;
  text-align: center;

  &_loading_area {
    width: 100% !important;
    position: absolute;
    top: 50%
  }
}

.member_manage_bg1 {
  width: 100%;
  background-color: $backGround;
}

.member_manage_bg2 {
  background-color: $keyWhite;

  @include pc() {
    width: 980px;
    margin: 0 auto;
  }

  @include mobile() {
    width: 100%;
    padding-bottom: 50px;
  }
}

.member_manage {
  background-color: $keyWhite;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 700px;
  margin: 0 auto;

  @include mobile() {
    padding: 25px 0 0 0;
  }
}

.member_manage_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 0 0 40px 0;

  .header_texts {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.member_manage_title {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 3px;
}

.member_manage_summary {
  margin: 0;
  padding: 0;
  margin-bottom: 17px;
  transform: scale(0.9);
  transform-origin: 0;
  font-size: 11px;
  line-height: calc(13px / 0.9);
}

.member_manage_summary_members {
  &>*:not(:last-child) {
    margin-right: 8px;
  }
}

.member_manage_invited {
  color: #FF221F;
}

hr {
  border: 0;
  border-top: 2px solid #000;
  margin: 0 0;
}

.main_area {
  width: 100%;
}

.search_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search_area {
  margin: 22px 16px;
}

.total_area {
  align-self: flex-start;
  margin: 10px 0;
}

.checkbox_area {
  width: 660px;
}

.form_label {
  display: inline-block;
  margin-bottom: 8px;
  font-size: 10px;
  font-weight: normal;
}

.checkbox_container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 4px;
  margin: 24px 0;
}

.member_count_label {
  font-weight: bold;
  font-size: 14px;
  margin: 0;
}

.custom_base_input {

  .form_input,
  .form_input_prepend,
  .form_input_append {
    border-radius: 0 !important;
  }
}

.select_block {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 4px;
  background-color: #fff;
  margin: 10px;
}

.select_label {
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
}

.select_description {
  font-size: 10px;
  margin: 0 0 12px;
}

.custom_select {
  width: 310px;
  color: #000;
}


.member_list {
  padding: 0;
  margin: 0 32px 16px;
  list-style: none;
  font-size: 11px;

  li {
    &:not(:last-child) {
      margin-bottom: 6px;
    }

    a {
      color: $KeyDarkGreen;
    }
  }
}

.status_user_member_list {
  padding: 0;
  margin: 0 32px 16px;
  list-style: none;
  font-size: 11px;
  column-count: 2;

  li {
    &:not(:last-child) {
      margin-bottom: 6px;
    }

    a {
      color: $KeyDarkGreen;
    }
  }
}

$textSize_Error: 13px;
$textSpecing_Error: 4px;
$textColor_Error: #ff0000;

.error_txt {
  font-size: $textSize_Error;
  margin: $textSpecing_Error 0 0 0;
  color: $textColor_Error;
  white-space: pre-wrap;
}

.csv_download_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;

  @media screen and (max-width: 375px) {
    display: none !important;
  }
}
</style>
