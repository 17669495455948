<template>
  <div
    :class="[
      $style.header,
      { [$style[`bg_${this.bgColor}`]]: !!this.bgColor },
      this.topFixed ? $style.top_fixed : '',
    ]"
  >
    <div :class="$style.sp_header_item">
      <div :class="$style.back_button_wrap">
        <base-icon
          v-if="back"
          name="arrow-left"
          size="21px"
          @click.native="backButtonAction(back)"
        />
      </div>

      <div :class="$style.title_wrap">
        <span>{{
          pageTitle.length > 20 ? pageTitle.slice(0, 20) + '...' : pageTitle
        }}</span>
      </div>

      <div :class="$style.menu_icon_wrap">
        <base-overlay-menu-list
          v-if="$slots['setting-menu'] && hasSetting"
          :show="showSetting"
          @show="(show) => $emit('show-setting', show)"
        >
          <slot name="setting-menu" />
        </base-overlay-menu-list>
      </div>
    </div>

    <div
      :class="$style.icon_wrap"
      @click="$router.push('/home/home', () => {})"
    >
      <img :class="$style.icon_logo" :src="logoPath" />
      <span :class="$style.icon_title">くみあいアプリ{{ branch_info }}</span>
    </div>
    <div :class="$style.icon_wrap" @click="refreshOrGoToTeamHome()">
      <div v-if="!showSearchBox && pageTitle" :class="$style.home_button_wrap">
        {{ pageTitle.length > 20 ? pageTitle.slice(0, 20) + '...' : pageTitle }}
      </div>
    </div>
    <div :class="$style.link_contents">
      <div ref="team_wrap" :class="$style.icon_wrap" @click="toggleShowTeam">
        <base-img :path="teamIconSrc" :class="$style.icon_img" :icon="true" />
        <span :class="[$style.icon_title, $style.icon_title_menu]">チーム</span>
        <base-icon
          :class="$style.icon_chevron"
          name="chevron-down"
          size="10px"
        />
        <div v-show="isShowTeam" :class="$style.link_contents_team">
          <div
            v-for="team in team_list"
            :class="$style.team_item_wrap"
            :key="team.team_id"
          >
            <app-header-team-item
              :head="team.name"
              :image="team.path"
              :border-color="
                team.team_color ? `rgb(${team.team_color})` : '#7BE293'
              "
              @click="goToTeam(team.team_id)"
            />
          </div>
        </div>
      </div>
      <div ref="sso_wrap" :class="$style.icon_wrap" @click="toggleShowSso">
        <base-img
          :path="serviceIconSrc"
          :class="$style.icon_img"
          :icon="true"
        />
        <span :class="[$style.icon_title, $style.icon_title_menu]"
          >連携サービス</span
        >
        <base-icon
          :class="$style.icon_chevron"
          name="chevron-down"
          size="10px"
        />
        <div v-show="isShowSso" :class="$style.link_contents_sso">
          <div :class="[$style.post_item_list, $style.sso_list_wrapper]">
            <div :class="$style.sso_item_wrap">
              <app-carousel-sso-item
                border-color="#E2E2E2"
                :service_id="1"
                :sso_list="reservation_history_url_list"
                @click="goToSso"
                :sso_login_url="sso_login_url_list[1]"
              />
            </div>
            <div :class="$style.sso_item_wrap" v-if="6 in sso_list">
              <app-carousel-sso-item
                border-color="#E2E2E2"
                :service_id="6"
                :sso_list="sso_list[6]"
                @click="goToSso"
              />
            </div>
            <div :class="$style.sso_item_wrap" v-if="2 in sso_list">
              <app-carousel-sso-item
                border-color="#E2E2E2"
                :service_id="2"
                :sso_list="sso_list[2]"
                @click="goToSso"
              />
            </div>
            <div :class="$style.sso_item_wrap" v-if="3 in sso_list">
              <app-carousel-sso-item
                border-color="#E2E2E2"
                :service_id="3"
                :sso_list="sso_list[3]"
                @click="goToSso"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        :class="$style.icon_wrap"
        @click="$router.push('/union/home', () => {})"
      >
        <base-img :path="unionIconSrc" :class="$style.icon_img" :icon="true" />
        <span :class="[$style.icon_title, $style.icon_title_menu]">MOVIE</span>
      </div>
      <div :class="$style.icon_wrap" @click="clickProfile">
        <base-avatar
          v-if="profileImgPath"
          size="40px"
          :bordered="false"
          :class="$style.avatar"
          :path="profileImgPath"
        />
      </div>
    </div>

    <!-- 検索入力欄 -->
    <div :class="$style.search_input_wrap" v-if="showSearchBox">
      <app-prev-button
        v-if="prevRoute"
        margin="0 0 46px"
        @click="$router.push(prevRoute)"
      />
      <div :class="$style.search_input_wrap">
        <base-input
          type="text"
          name="search_input"
          look="rect"
          v-model="keyWord"
          :searchBar="true"
          @keydown.enter.native="searchWordHandler"
        >
          <template v-slot:prepend>
            <base-icon name="search" size="18px" color="green2" />
          </template>
        </base-input>
      </div>
    </div>

    <div style="display: flex; align-items: center">
      <div
        v-if="$slots['setting-menu'] && hasSetting"
        :class="$style.menu_button_wrap"
      >
        <base-overlay-menu-list
          :show="showSetting"
          @show="(show) => $emit('show-setting', show)"
        >
          <template v-slot:menu-button>
            <base-button
              width="75px"
              height="32px"
              size="s"
              bgColor="white"
              color="black"
              rounded="shallow-corner"
              bordered
            >
              <base-icon
                name="ellipsis-h"
                :style="{ 'padding-right': '10px' }"
              />
              設定
            </base-button>
          </template>
          <slot name="setting-menu" />
        </base-overlay-menu-list>
      </div>
      <div v-if="showIcon">
        <slot name="team-icon"></slot>
      </div>
      <div v-if="showTabbar" :class="$style.tabbar_wrap">
        <app-home-menu v-if="isShowMenu" @close="isShowMenu = false" />
        <app-tabbar
          @menu="toggleShowMenu()"
          :news="showDots"
          :is-show-menu="isShowMenu"
        />
      </div>
      <div v-else :class="$style.right_contents"></div>
    </div>
  </div>
</template>

<script>
import AppTabbar from '../AppTabbar/AppTabbar'
import AppHomeMenu from '@/components/app/AppMenu/AppHomeMenu/AppHomeMenu'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import BaseIcon from '../../base/BaseIcon/BaseIcon'
import BaseInput from '@/components/base/BaseInput/BaseInput'
import BaseOverlayMenuList from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuList'
import BaseImg from '@/components/base/BaseImg/BaseImg'
import AppHeaderTeamItem from '@/components/app/AppHeader/TeamItem/AppHeaderTeamItem'
import AppCarouselSsoItem from '@/components/app/AppCarousel/SsoItem/AppCarouselSsoItem.vue'
import BaseAvatar from '@/components/base/BaseAvatar/BaseAvatar'
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'
import VueCookies from 'vue-cookies'
const cookies = VueCookies

export default {
  name: 'AppHeader',
  components: {
    BaseOverlayMenuList,
    AppTabbar,
    AppHomeMenu,
    BaseButton,
    BaseIcon,
    BaseInput,
    BaseImg,
    AppHeaderTeamItem,
    AppCarouselSsoItem,
    BaseAvatar,
    AppPrevButton,
  },
  mounted() {
    this.sso_login_url_list =
      this.sso_login_url_list_all[process.env.VUE_APP_NODE_ENV]
    this.reservation_history_url_list =
      this.reservation_history_url_list_all[process.env.VUE_APP_NODE_ENV]
    this.getUserNotification()
    this.initFetch()
  },
  props: {
    back: {
      type: [String, Object],
      default: '',
    },
    bgColor: {
      type: String,
      default: 'white',
      validator: (bg_color) =>
        !bg_color || ['white', 'yellow'].includes(bg_color),
    },
    pageTitle: {
      type: String,
      default: undefined,
    },
    hasSetting: {
      type: Boolean,
      default: true,
    },
    showSetting: {
      type: Boolean,
      default: false,
    },
    showTabbar: {
      type: Boolean,
      default: true,
    },
    showSearchBox: {
      type: Boolean,
      default: false,
    },
    topFixed: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    prevRoute: {
      type: String,
      default: undefined,
    },
    teamId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      navItem: {
        about: {
          label: '運営会社について',
          path: '/about',
          check: false,
        },
        faq: {
          label: 'よくある質問',
          path: '/faq',
          check: false,
        },
        contact: {
          label: 'お問い合わせ',
          path: '/contact',
          check: false,
        },
        teamsOfService: {
          label: '利用規約',
          path: '/teams-of-service',
          check: false,
        },
        privacyPolicy: {
          label: '個人情報保護方針',
          path: '/privacy-policy',
          check: false,
        },
      },
      isShowMenu: false,
      showDots: {
        home: false,
        notice: false,
        search: false,
        favorite: false,
        menu: false,
      },
      branch_info: process.env.VUE_APP_BRANCH_INFO,
      team_list: [],
      isShowTeam: false,
      isShowSso: false,
      sso_list: {},
      sso_login_url_list_all: {
        local: {
          1: {
            sso_url:
              'https://j-union.revn5-2.demo.iqnet.co.jp/auth/signon?uuid=',
            redirect_url: 'https://j-union.revn5-2.demo.iqnet.co.jp',
          },
        },
        develop: {
          1: {
            sso_url:
              'https://j-union.revn5-2.demo.iqnet.co.jp/auth/signon?uuid=',
            redirect_url: 'https://j-union.revn5-2.demo.iqnet.co.jp',
          },
        },
        staging: {
          1: {
            sso_url:
              'https://j-union.revn5-2.demo.iqnet.co.jp/auth/signon?uuid=',
            redirect_url: 'https://j-union.revn5-2.demo.iqnet.co.jp',
          },
        },
        production: {
          1: {
            sso_url: 'https://event.revn.jp/auth/signon?uuid=',
            redirect_url: 'https://event.revn.jp',
          },
        },
      },
      sso_login_url_list: {},
      reservation_history_url_list_all: {
        local: {
          1: {
            team_name: '予約履歴へ',
            sso_url:
              'https://j-union.revn5-2.demo.iqnet.co.jp/auth/signon?uuid=',
            redirect_url:
              'https://j-union.revn5-2.demo.iqnet.co.jp/reservations/history',
          },
        },
        develop: {
          1: {
            team_name: '予約履歴へ',
            sso_url:
              'https://j-union.revn5-2.demo.iqnet.co.jp/auth/signon?uuid=',
            redirect_url:
              'https://j-union.revn5-2.demo.iqnet.co.jp/reservations/history',
          },
        },
        staging: {
          1: {
            team_name: '予約履歴へ',
            sso_url:
              'https://j-union.revn5-2.demo.iqnet.co.jp/auth/signon?uuid=',
            redirect_url:
              'https://j-union.revn5-2.demo.iqnet.co.jp/reservations/history',
          },
        },
        production: {
          1: {
            team_name: '予約履歴へ',
            sso_url: 'https://event.revn.jp/auth/signon?uuid=',
            redirect_url: 'https://event.revn.jp/reservations/history',
          },
        },
      },
      reservation_history_url_list: {},
      chat_list: {},
      profileImgPath: null,
      access_token: null,
      refresh_token: null,
    }
  },
  computed: {
    logoPath() {
      return require('@/assets/images/nav_logo1.svg')
    },
    teamIconSrc() {
      return require('@/assets/images/header/icon-team.svg')
    },
    serviceIconSrc() {
      return require('@/assets/images/header/icon-service.svg')
    },
    unionIconSrc() {
      return require('@/assets/images/header/icon-union.svg')
    },
  },
  watch: {
    // fromがteam/home/{team_id}の場合、描画が更新されない対応
    $route() {
      location.reload()
    },
  },
  async created() {
    // this.$router.app.$emit('show-spinner', true)
    // await this.initFetch()
    // this.$router.app.$emit('show-spinner', false)

    const auth_info = cookies.get('auth_info')
    if (auth_info.access_token) this.access_token = auth_info.access_token
    if (auth_info.refresh_token) this.refresh_token = auth_info.refresh_token
  },
  methods: {
    async goToSso(sso) {

      // IRET_JUNION_UNI_VERSE-2496 期限が切れたアクセストークンの更新を行うためログインチェックAPIを呼び出す
      await this.$axios.get('/api/' + this.$constants.API_VERSION + '/auth/token/check', {}).then((response) => {
        if (response.status != 200) {
          return
        }
      }).catch((error) => {
        console.log(error)
        return
      })
      const auth_info = cookies.get('auth_info')
      if (auth_info.access_token) this.access_token = auth_info.access_token
      if (auth_info.refresh_token) this.refresh_token = auth_info.refresh_token

      let popup = null
      // SSOリクエスト取得処理
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/auth/sso/request', {
          redirect_url: encodeURIComponent(sso.redirect_url),
          error_url: encodeURIComponent(
            `${process.env.VUE_APP_BASE_URL}/error/404`
          ),
          access_token: this.access_token,
          refresh_token: this.refresh_token,
          device: 'PC',
          tel_or_email: this.$store.state.auth.tel_or_email,
        })
        .then((response) => {
          if (response.status != 200) {
            return
          }
          // ポップアップブロックON対策
          popup = window.open(sso.sso_url + response.data.value.uuid)
          if (!popup || popup.closed || typeof popup.closed === 'undefined') {
              alert('くみあいアプリからのお知らせ\n連携サービスへのポップアップがブロックされました。\n端末・ブラウザの設定からポップアップブロックを解除してください。');
          }
        })
        .catch((error) => {
          if (popup) {
            popup.close();
          }
          this.$logerror(error)
          alert(error)
        })
    },
    getUserInfo() {
      this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/user/info')
        .then((response) => {
          this.nickname = response.data.value.user.nickname
          this.profileImgPath = response.data.value.user.profile_image_path
        })
        .catch((error) => {
          this.$logerror(error)
        })
    },
    searchResultWord: function () {
      return this.keyWord && this.keyWord != ''
        ? this.keyWord
        : 'キーワードなし'
    },
    searchWordHandler() {
      if (this.keyWord == '') {
        this.$router.push('/home/search')
      } else {
        this.$router.push({
          path: '/home/search',
          query: { search_word: this.keyWord },
        })
      }
    },
    toggleShowMenu() {
      this.isShowTeam = false
      this.isShowSso = false
      this.isShowMenu = !this.isShowMenu
    },
    toggleShowTeam() {
      this.isShowMenu = false
      this.isShowSso = false
      this.isShowTeam = !this.isShowTeam

      // アイコンをクリックしたときに非表示になるようにする
      if (this.isShowTeam) {
        document.addEventListener('click', this.hideTeamOnClickOutside)
      } else {
        document.removeEventListener('click', this.hideTeamOnClickOutside)
      }
    },
    hideTeamOnClickOutside(event) {
      // クリックされた要素がアイコン以外の場合は非表示にする
      const teamWrap = this.$refs.team_wrap
      if (teamWrap && !teamWrap.contains(event.target)) {
        this.isShowTeam = false
      }
    },
    toggleShowSso() {
      this.isShowMenu = false
      this.isShowTeam = false
      this.isShowSso = !this.isShowSso

      // アイコンをクリックしたときに非表示になるようにする
      if (this.isShowSso) {
        document.addEventListener('click', this.hideSsoOnClickOutside)
      } else {
        document.removeEventListener('click', this.hideSsoOnClickOutside)
      }
    },
    hideSsoOnClickOutside(event) {
      // クリックされた要素がアイコン以外の場合は非表示にする
      const SsoWrap = this.$refs.sso_wrap
      if (SsoWrap && !SsoWrap.contains(event.target)) {
        this.isShowSso = false
      }
    },
    fetchTeamList() {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/team/list', {
          params: {
            search_type: 'login',
            sort: 'name',
            direction: 'asc',
            limit: 1000,
          },
        })
        .then((res) => {
          this.team_list = res.data.value.team_list
        })
    },
    // 組織の投稿一覧
    fetchSsoChatList() {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/team/chat/list', {
          params: {
            type: '1,2,3,4',
            display_type: 'all',
            sort: 'created_at',
            direction: 'desc',
            limit: '8',
            is_ignore_pin: '1',
          },
        })
        .then((res) => {
          this.chat_list = res.data.value.chats
        })
    },
    fetchSsoList() {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/auth/sso/list')
        .then((res) => {
          const sso = res.data.value

          const obj = {}
          for (let i = 0; i < sso.length; i++) {
            const sso_item = sso[i]
            const service_id = sso_item.service_id

            switch (true) {
              case /^[1-6]$/.test(service_id):
                if (!(service_id in obj)) obj[service_id] = []
                obj[sso_item.service_id].push(sso_item)

                if (!(service_id in this.chat_list))
                  this.chat_list[service_id] = []

                break
              default:
                break
            }
          }
          this.sso_list = Object.assign({}, this.sso_list, obj)
        })
    },
    getUserNotification() {
      this.$axios
        .get(
          '/api/' + this.$constants.API_VERSION + '/user/notification/unread',
          {
            params: {
              limit: 20,
            },
          }
        )
        .then((response) => {
          this.showDots.notice = Boolean(response.data.value.unread_flg)
        })
    },
    backButtonAction(back) {
      if (back === '__history_back' || back.force_history_back) {
        if (back.force_history_back) {
          let old_history_state = history.state
          old_history_state.from_name = null
          history.replaceState(old_history_state, '')
        }
        this.$router.go(-1)
      } else {
        this.$router.push(back, () => {})
      }
    },
    goToTeam(team_id) {
      // 素早くWクリックした際のNavigationDuplicatedエラー防止のため、同じパスに遷移できないようにしている
      if (this.$route.path != `/team/home/${team_id}`) {
        this.$router.push(`/team/home/${team_id}`)
      }
    },
    clickProfile: function () {
      if (this.$route.path !== '/home/profile') {
        this.$router.push({ name: 'HomeProfile' })
      }
    },
    async initFetch() {
      await Promise.all([
        // this.fetchFeaturedChatList(),
        this.fetchTeamList(),
        this.fetchSsoList(),
        this.getUserInfo(),
        // this.fetchTeamBelongList(),
        // this.fetchServiceNewsList(),
        // this.fetchSsoChatList(),
        // this.fetchSsoList(),
        // this.getUserInfo(),
      ])
    },
    refreshOrGoToTeamHome() {
      const currentPath = this.$route.path
      // 現在のURLが以下のパスを含む場合、チームHOMEに遷移
      if (
        currentPath.includes('team/post-create/') ||
        currentPath.includes('team/mini-vote-create/') ||
        currentPath.includes('team/info/')
      ) {
        this.$router.push('/team/home/' + this.teamId).catch(() => {})
      } else {
        // それ以外の場合、現在のページを再読み込み
        this.$router.go(0)
      }
    },
  },
}
</script>

<style lang="scss" module>
.header {
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $borderLiteGray;
  padding: 0 30px;

  @media screen and (max-width: 768px) {
    height: 50px;
    background-color: $keyWhite;
    border-top: 2px solid $keyYellow;
  }

  &.bg_white {
    background-color: $keyWhite;
  }
  &.bg_yellow {
    background-color: $keyYellow;
  }
  &.top_fixed {
    position: fixed;
    z-index: 10;
  }
}
.icon_wrap {
  display: flex;
  align-items: center;
  white-space: nowrap;
  // margin-left: 25px;
  cursor: pointer;
  position: relative;

  @media screen and (max-width: 768px) {
    display: none;
  }

  .icon_logo {
    width: 26px;
    height: 26px;
    padding-right: 10px;
  }

  .icon_title {
    font-size: 13px;
    font-weight: bold;
    &.icon_title_menu {
      @media screen and (max-width: 1380px) {
        display: none;
      }
    }
  }
  // 新規追加 202402
  .icon_chevron {
    margin-left: 5px;
  }
  .icon_img {
    margin-right: 5px;
  }
}

.home_button_wrap {
  margin-left: 65px;
  font-weight: bold;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.menu_button_wrap {
  margin-right: 15px;
  @media screen and (max-width: 768px) {
    display: none;
  }
  z-index: 100; // ヘッダーの持つオーバーレイメニューをページのものよりも上にする
}

.sp_header_item {
  display: none;

  @media screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .back_button_wrap {
      display: flex;
      width: 50px;
      height: 50px;
      justify-content: center;
      a {
        color: #525252;
      }
      align-items: center;
    }

    .title_wrap {
      font-size: 14px;
      font-weight: bold;
    }

    .menu_icon_wrap {
      display: flex;
      width: 50px;
      height: 50px;
      justify-content: center;
      align-items: center;
      z-index: 100; // ヘッダーの持つオーバーレイメニューをページのものよりも上にする
    }
  }
}
.tabbar_wrap {
  @media screen and (max-width: 768px) {
    background: #fff;
    box-sizing: border-box;
    border-top: 1px solid $borderLiteGray;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 11;
  }
}

.search_wrap {
  max-width: 240px;

  @media screen and (max-width: 768px) {
    display: none;
  }

  .search_input_wrap {
    margin: 0 25px;
  }
}

.right_contents {
  flex-grow: 1;
  min-width: 100px;
  max-width: 300px;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
// 新規追加 202402
.link_contents {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  transition: 0.5s;
  gap: 30px;
  margin-right: 30px;
  margin-left: 20px;
  @media screen and (max-width: 1190px) {
    display: none;
  }
}

.link_contents_team,
.link_contents_sso {
  position: absolute;
  padding: 30px;
  background-color: $keyWhite;
  border-radius: 20px;
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.1);
  z-index: 100; // ヘッダーの持つオーバーレイメニューをページのものよりも上にする
  top: 60px;
  max-height: 60vh;
}
// .link_contents_team,
.link_contents_sso {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  overflow: scroll;
  left: -35vw;
  width: 1020px;
  max-width: 70vw;
  @media screen and (max-width: 1080px) {
    max-width: 60vw;
  }
}
.link_contents_team {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
  width: 60vw;
  max-width: 980px;
  overflow: scroll;
  left: -30vw;
  @media screen and (max-width: 1080px) {
    grid-template-columns: repeat(3, 1fr);
  }
}
.link_contents_sso {
  width: 1020px;
}
.team_item_wrap {
  min-width: 180px;
}
.post_item_list {
  display: flex;
  gap: 20px;
}
</style>
